import $ from "jquery";

export class LogApi {
    // static setContactFormViewed(payload) {
    //     return $.ajax(`/api/log/contact-form-viewed`, {
    //         type: "POST",
    //         dataType: "json",
    //         contentType: "application/json; charset=utf-8",
    //         data: JSON.stringify(payload)
    //     });
    // }

    static setDealerSelected(payload) {
        return $.ajax(`/api/log/dealer-selected`, {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(payload)
        });
    }

    static setExistingCustomer() {
        return $.ajax(`/api/log/existing-customer`, {
            dataType: "json",
            contentType: "application/json; charset=utf-8"
        });
    }

    static setCustomer(payload) {
        return $.ajax(`/api/log/customer`, {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(payload)
        });
    }

    static setWorkTypes(payload) {
        return $.ajax(`/api/log/worktypes`, {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(payload)
        });
    }
}