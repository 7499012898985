import React from "react";
import {useDispatch} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js";
import "./system-type-page.css";

export default function PetEscapingPage({onIssueSelection}) {
    const dispatch = useDispatch();

    const onIssueSelected = (issueId) => {
        dispatch(onIssueSelection(parseInt(issueId)));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <span className="scheduler-text-heading" style={{color: "#0067b1"}}>
                        Does your system seem like it's running?
                    </span>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12 col-md-6">
                    <button onClick={() => onIssueSelected(1)} className="btn btn-primary btn-lg w-100">Yes, I think
                        it's a
                        training
                        issue
                    </button>
                </div>
                <div className="col-12 col-md-6">
                    <button onClick={() => onIssueSelected(2)} className="btn btn-primary btn-lg w-100">No, I think it's
                        a
                        system
                        issue
                    </button>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <h4 style={{color: "black"}}>Try these tips to ensure your pet is protected:</h4>
                </div>
                <div className="col-12">
                    <ul>
                        <li><a target="_blank" href="https://info.invisiblefence.com/changing-power-cap-battery" style={{textDecoration: "underline"}}>Make sure your collar has a working battery</a></li>
                        <li><a target="_blank" href="https://www.youtube.com/watch?v=SyEmD-1iE1U" style={{textDecoration: "underline"}}>Check that your pet’s collar fits snug around their neck</a></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}