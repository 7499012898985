import react from 'react';
import React from "react";

export default function SystemType({systemTypeId, systemTypeName, displayName, description, imageUrl, onSystemSelection}) {
    return (
        <div onClick={() => onSystemSelection(systemTypeId, systemTypeName)}
             className="card my-2 systemCard">
            <div className="card-header text-center"><h3>{displayName}</h3></div>
            <div className="card-body text-center">
                <img className="systemImg" src={imageUrl} alt={displayName}/>
            </div>
            {description && 
                <div className="card-footer text-center">{description}</div>
            }
        </div>
    )
}