import {createSlice} from "@reduxjs/toolkit";
import buildCalendar from "../calendar/calendar";
import {ServiceApi} from "../api/ServiceApi";
import {loadingScreenActions} from "./LoadingScreenModule";
import AppointmentLoadingScreen from "../components/prospect-scheduling/AppointmentLoadingScreen";
import {modalActions} from "./ModalModule";
import {schedulingActions} from "./SchedulingModule";
import {formatDate} from "../utility/utilities";

let initialCalendar = buildCalendar();

export const ServiceViews = {
    BasePath: {
        Path: 'service',
        Title: 'Service Scheduler - '
    },
    AppointmentList: {
        Path: 'appointments',
        Title: 'Appointments'
    },
    VerifyAddress: {
        Path: 'verify-address',
        Title: 'Verify Address'
    },
    SystemType: {
        Path: 'system-type',
        Title: 'System Type'
    },
    FenceIssue: {
        Path: 'fence-issue',
        Title: 'Fence Issue'
    },
    SystemIssue: {
        Path: 'system-issue',
        Title: 'System Issue'
    },
    SystemDown: {
        Path: 'system-down',
        Title: 'System Down'
    },
    PetEscaping: {
        Path: 'pet-escaping',
        Title: 'Pet Escaping'
    },
    TrainingPetType: {
        Path: 'pet-type',
        Title: 'Training'
    },
    Appointments: {
        Path: 'appointments',
        Title: 'Appointment Selection'
    },
    Review: {
        Path: 'review',
        Title: 'Review Appointment'
    },
    Confirmation: {
        Path: 'confirmation',
        Title: 'Confirmation'
    },
    OtherIssueConfirmation: {
        Path: 'other-issue-confirmation',
        Title: 'Other Issue Confirmation'
    },
    Errors: {
        Appointments: {
            Path: 'e/appointments',
            LocalPath: 'e/appointments',
            Title: "E: Appointment Selection (Service)"
        },
        Schedule: {
            Path: 'e/schedule',
            LocalPath: 'e/schedule',
            Title: "E: Scheduling (Service)"
        },
    }
}

const serviceState = {
    currentView: ServiceViews.VerifyAddress,
    contactAddress: "",
    serviceRequest: {
        systemType: {
            systemTypeId: 0,
            system: ""
        },
        systemIssueId: 0,
        comments: "",
        hasMajorLandscaping: false,
        systemDownLength: 0,
        trainingIssueId: 0,
        trainingPetTypeId: 0
    },
    reviewForm: null,
    workType: null,
    defaultPaymentMethod: null,
    defaultPhone: null,
    appointments: [],
    appointmentScheduled: false,
    reloadAppointments: false,
    appointmentsLoaded: false,
    calendar: initialCalendar,
    selectedMonth: initialCalendar.months[0],
    selectedDay: null,
    selectedAppointment: null,
    scheduledAppointment: null,
    error: null
};

const PAGE_TITLES = {
    VerifyAddress: 'IFB Scheduler - Verify Address'
};

let serviceReducers = {
    clearState: (state, action) => {
        state.currentView = ServiceViews.VerifyAddress;
        state.serviceRequest = {
            systemType: {
                systemTypeId: 0,
                system: ""
            },
            systemIssueId: 0,
            comments: "",
            hasMajorLandscaping: false,
            systemDownLength: 0,
            trainingIssueId: 0,
            trainingPetTypeId: 0
        };
        state.reviewForm = null;
        state.workType = null;
        state.defaultPaymentMethod = null;
        state.defaultPhone = null;
        state.appointments = [];
        state.appointmentScheduled = false;
        state.reloadAppointments = false;
        state.appointmentsLoaded = false;
        state.calendar = initialCalendar;
        state.selectedMonth = initialCalendar.months[0];
        state.selectedDay = null;
        state.selectedAppointment = null;
        state.scheduledAppointment = null;
        state.error = null;
    },
    setContactAddress: (state, action) => {
        state.contactAddress = action.payload;
    },
    setCustomerDetails: (state, action) => {
        state.defaultPaymentMethod = action.payload.paymentMethod;
        state.defaultPhone = action.payload.phone;
    },
    setSystemType: (state, action) => {
        state.serviceRequest.systemType = action.payload;

        switch (state.serviceRequest.systemType.systemTypeId) {
            case 4: //Doorman
            case 6: //LTE
                state.currentView = ServiceViews.SystemIssue;
                break;
            default:
                state.currentView = ServiceViews.FenceIssue;
                break;
        }

    },
    setFenceIssueForm: (state, action) => {
        state.serviceRequest.systemIssueId = action.payload.systemIssueId;
        state.serviceRequest.comments = action.payload.comments;
    },
    setSystemIssueForm: (state, action) => {
        state.serviceRequest.comments = action.payload.comments;
    },
    setSystemDownForm: (state, action) => {
        state.serviceRequest.hasMajorLandscaping = action.payload.hasMajorLandscaping;
        state.serviceRequest.systemDownLength = action.payload.systemDownLength;
    },
    setTrainingIssueId: (state, action) => {
        state.serviceRequest.trainingIssueId = action.payload;
    },
    setTrainingPetTypeId: (state, action) => {
        state.serviceRequest.trainingPetTypeId = action.payload;
    },
    setWorkType: (state, action) => {
        state.workType = action.payload;
    },
    appointmentSlotsLoaded: (state, action) => {
        state.calendar = buildCalendar(action.payload);
        state.selectedMonth = state.calendar.months[0];
        state.appointmentsLoaded = true;
    },
    appointmentSlotsError: (state, action) => {
        state.currentView = ServiceViews.Errors.Appointments;
    },
    schedulingError: (state, action) => {
        state.currentView = ServiceViews.Errors.Schedule;
    },
    daySelected: (state, action) => {
        state.selectedDay = action.payload;
    },
    selectNextMonth: (state, action) => {
        let index = state.selectedMonth.index + 1;
        if (index < state.calendar.months.length) {
            state.selectedMonth = state.calendar.months[index];
        }
    },
    selectPreviousMonth: (state, action) => {
        let index = state.selectedMonth.index - 1;
        if (index >= 0) {
            state.selectedMonth = state.calendar.months[index];
        }
    },
    setSelectedAppointment: (state, action) => {
        state.selectedAppointment = action.payload;
    },
    setDefaultPaymentMethod: (state, action) => {
        state.defaultPaymentMethod = action.payload;
    },
    setReviewForm: (state, action) => {
        state.reviewForm = action.payload;
    },
    setScheduledAppointment: (state, action) => {
        state.scheduledAppointment = action.payload;
    },
    showAppointmentListPage: (state, action) => {
        state.currentView = ServiceViews.AppointmentList;
    },
    showVerifyAddressPage: (state, action) => {
        document.title = PAGE_TITLES.VerifyAddress;
        state.currentView = ServiceViews.VerifyAddress;
    },
    showSystemTypePage: (state, action) => {
        state.currentView = ServiceViews.SystemType;
    },
    showFenceIssuePage: (state, action) => {
        state.currentView = ServiceViews.FenceIssue;
    },
    showSystemDownPage: (state, action) => {
        state.currentView = ServiceViews.SystemDown;
    },
    showPetEscapingPage: (state, action) => {
        state.currentView = ServiceViews.PetEscaping;
    },
    showTrainingPetTypePage: (state, action) => {
        state.currentView = ServiceViews.TrainingPetType;
    },
    showAppointmentsPage: (state, action) => {
        state.currentView = ServiceViews.Appointments;
    },
    showReviewPage: (state, action) => {
        state.currentView = ServiceViews.Review;
    },
    showConfirmationPage: (state, action) => {
        state.currentView = ServiceViews.Confirmation;
    },
    showOtherIssueConfirmationPage: (state, action) => {
        state.currentView = ServiceViews.OtherIssueConfirmation;
    }
};

const serviceModule = createSlice({
    name: "service",
    initialState: serviceState,
    reducers: serviceReducers
});

let asyncServiceActions = {
    showVerifyAddressPage: () => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.showVerifyAddressPage());
        }
    },
    onVerifyAddressNext: () => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.showSystemTypePage());
        }
    },
    onSystemTypeSelection: (systemType) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setSystemType(systemType))
        }
    },
    onSystemTypeBack: () => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.showVerifyAddressPage());
        }
    },
    onFenceIssueBack: () => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.showSystemTypePage());
        }
    },
    onFenceIssueFormSubmitted: (form) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setFenceIssueForm(form));

            switch (getState().service.serviceRequest.systemIssueId) {
                case 1:
                case 2:
                    switch(getState().service.serviceRequest.systemType.systemTypeId) {
                        case 4:
                        case 6:
                            dispatch(serviceModule.actions.showAppointmentsPage());                            
                            break;
                        default:
                            dispatch(serviceModule.actions.showSystemDownPage());
                    }                    
                    break;
                case 3:
                    dispatch(serviceModule.actions.showPetEscapingPage());
                    break;
                case 4:
                    dispatch(serviceActions.createOtherIssueCase());
                    break;
                default:
                //??                    
            }

        }
    },
    onSystemIssueFormSubmitted: (form) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setSystemIssueForm(form));
            dispatch(serviceModule.actions.showAppointmentsPage());
        }
    },
    createOtherIssueCase: () => {
        return async (dispatch, getState) => {
            //dispatch(loadingScreenActions.setDefaultLoadingScreen());
            let request = getState().service.serviceRequest;
            let resp = await ServiceApi.createOtherIssueCase(request);

            dispatch(serviceModule.actions.showOtherIssueConfirmationPage());
        }
    },
    onSystemDownFormSubmitted: (form) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setSystemDownForm(form));
            dispatch(serviceModule.actions.showAppointmentsPage());
        }
    },
    onPetEscapingSelection: (petEscapingIssueId) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setTrainingIssueId(petEscapingIssueId));

            if (petEscapingIssueId === 1) {
                dispatch(serviceModule.actions.showTrainingPetTypePage());
            } else {
                dispatch(serviceModule.actions.showAppointmentsPage());
            }

        }
    },
    onTrainingPetTypeSelection: (petTypeId) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setTrainingPetTypeId(petTypeId));
            dispatch(serviceModule.actions.showAppointmentsPage());
        }
    },
    onReviewFormBack: () => {
        return async (dispatch, getState) => {
            let payload = {
                text: `Are you sure you want to go back? You will need to select a new appointment.`,
                action: () => {
                    dispatch(serviceModule.actions.showAppointmentsPage());
                }
            }
            dispatch(modalActions.showConfirm(payload));
        }
    },
    getSlots: () => {
        return async (dispatch, getState) => {
            try {
                let request = getState().service.serviceRequest;

                dispatch(loadingScreenActions.setCustomLoadingScreen(AppointmentLoadingScreen));

                let getAppointmentsResponse = await ServiceApi.getAppointments(request);

                let customer = await ServiceApi.getCustomerDetails();
                dispatch(serviceModule.actions.setCustomerDetails(customer));

                dispatch(loadingScreenActions.setDefaultLoadingScreen());

                let slots = getAppointmentsResponse.slots;

                slots = slots || {};

                if (slots == null) {
                    dispatch(serviceModule.actions.appointmentSlotsError());
                    return;
                }

                dispatch(serviceModule.actions.setWorkType(getAppointmentsResponse.workType));

                dispatch(serviceModule.actions.appointmentSlotsLoaded(slots));

            } catch (ex) {
                dispatch(serviceModule.actions.appointmentSlotsError());
            }
        }
    },
    onSlotClick: (slot) => {
        return async (dispatch, getState) => {

            let payload = {
                text: `Please confirm your date and time:<br/> <b>Date: </b> ${formatDate(slot.start)}<br/><b>Estimated Arrival Window: </b>${slot.slotDisplay}`,
                action: () => {
                    dispatch(serviceModule.actions.setSelectedAppointment(slot));
                    dispatch(serviceModule.actions.showReviewPage());
                }
            }
            dispatch(modalActions.showConfirm(payload));

            // dispatch(serviceModule.actions.setSelectedAppointment(slot));
            // dispatch(serviceModule.actions.showReviewPage());
        }
    },
    onAppointmentsPageBack: () => {
        return async (dispatch, getState) => {
            let payload = {
                text: `Are you sure you want to start over?`,
                action: () => {
                    dispatch(serviceModule.actions.clearState());
                    dispatch(serviceActions.returnToAppointmentList());
                }
            }
            dispatch(modalActions.showConfirm(payload));
        }
    },
    returnToAppointmentList: () => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.clearState());
            //dispatch(schedulingActions.reloadAppointments());
            dispatch(serviceModule.actions.showAppointmentListPage());
        }
    },
    onReviewFormSubmitted: (form) => {
        return async (dispatch, getState) => {
            dispatch(serviceModule.actions.setReviewForm(form));
            dispatch(serviceActions.scheduleAppointment());
        }
    },
    scheduleAppointment: () => {
        return async (dispatch, getState) => {
            try {
                let request = {
                    slot: getState().service.selectedAppointment,
                    workType: getState().service.workType,
                    serviceAppointmentRequest: getState().service.serviceRequest,
                    reviewForm: getState().service.reviewForm
                };

                let saveAppointmentResponse = await ServiceApi.scheduleAppointment(request);

                dispatch(serviceModule.actions.setScheduledAppointment(saveAppointmentResponse));
                dispatch(serviceModule.actions.showConfirmationPage());

                dispatch(schedulingActions.reloadAppointments());
            } catch (ex) {
                dispatch(serviceModule.actions.schedulingError());
            }
        }
    }
}

export const serviceActions = {...serviceModule.actions, ...asyncServiceActions}
export default serviceModule.reducer;