import React, {useEffect} from "react";
import {formatDate} from "../../utility/utilities";
import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";

export default function ConfirmationPage({apptId, workType, appointmentDate, slotDisplay, onReturnHome}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    useEffect(() => {
        searchParams.set("APPT_ID", apptId);
        setSearchParams(searchParams);
    }, []);

    const returnHome = () => {
        dispatch(onReturnHome());
    }

    return (
        <div className="container">
            <div className="row pt-3">
                <div className="col-12 text-center">
                    <h2>Your appointment is scheduled!</h2>
                    <p>Your {workType} appointment is scheduled
                        on <span className="text-nowrap font-weight-bold">{formatDate(appointmentDate)}</span> with estimated arrival window
                        from <span className="text-nowrap font-weight-bold">{slotDisplay}</span>.</p>
                    <p>We will send you a confirmation email the day prior to your appointment with an estimated arrival time for your technician.</p>
                    <h3 style={{color: "darkred", fontWeight: "bold"}}>Please keep your pet(s) on leash until your
                        system is fixed.</h3>
                    <p>If you have any questions or need to re-schedule your appointment, <span className="click-link" onClick={() => returnHome()}>click here</span> or contact us at:</p>
                    <span className="text-nowrap">
                        <a href="tel:8008243647">(800)&nbsp;824-3647</a>
                    </span>
                    <br/>
                    <span className="text-nowrap">
                        <a href="mailto:ccc@invisiblefence.net">ccc@invisiblefence.net</a>
                    </span>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12 text-center">
                    <button onClick={() => returnHome()} className="btn btn-primary btn-lg">Return Home</button>
                </div>
            </div>
        </div>
    )
}