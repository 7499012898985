import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {prospectActions, ProspectViews} from "../../redux-modules/ProspectModule";
import useSchedulerLogging from "../../hooks/useSchedulerLogging";

export default function RedirectHandler() {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {setContactFormViewed} = useSchedulerLogging();

    useEffect(() => {

        const processRedirect = async () => {
            let fName = searchParams.get("firstName");
            let lName = searchParams.get("lastName");
            let address = searchParams.get("address");
            let city = searchParams.get("city");
            let state = searchParams.get("state");
            let zip = searchParams.get("zip");
            let phoneNumber = searchParams.get("phoneNumber");
            let email = searchParams.get("email");
            let promo = searchParams.get("promo");

            if (!(fName && lName && address && zip && phoneNumber && email) || zip.length < 5) {
                navigate(`/${ProspectViews.BasePath.Path}/${ProspectViews.ContactForm.Path}`);
                dispatch(prospectActions.showContactForm());
                return;
            }

            let utmSource = searchParams.get("source");
            let utmCampaign = searchParams.get("campaign");
            let utmMedium = searchParams.get("medium");
            let path = searchParams.get("path");

            var href = `${window.location.origin}/${path}`;
            var origin = window.location.origin;
            var windowPath = `/prospect/contact/${path}`;

            var urlParams = "";
            urlParams += `?promo=${(promo === "undefined" ? "" : promo)}&UTM_Campaign=${(utmCampaign === "undefined" ? "" : utmCampaign)}&UTM_Source=${(utmSource === "undefined" ? "" : utmSource)}&UTM_Medium=${(utmMedium === "undefined" ? "" : utmMedium)}`;

            await setContactFormViewed(href, origin, windowPath, urlParams);

            let formData = {
                firstName: fName,
                lastName: lName,
                address: address,
                city: city,
                state: state,
                zip: zip,
                phoneNumber: phoneNumber,
                email: email,
                promo: promo
            };

            dispatch(prospectActions.onContactFormSubmit(formData, process.env.REACT_APP_RECAPTCHA_BYPASS_KEY));

        }

        processRedirect();

    }, [])

    return (<></>)

}