const REACT_EXCLUDE_METHODS = {
    getChildContext: true,
    render: true,
    componentWillMount: true,
    componentDidMount: true,
    componentWillReceiveProps: true,
    shouldComponentUpdate: true,
    componentWillUpdate: true,
    componentDidUpdate: true,
    componentWillUnmount: true
};

function isExcluded(methodName) {
    return (REACT_EXCLUDE_METHODS[methodName] === true);
}

function autobind(classInstance, prototype) {
    if (prototype == null) {
        prototype = Object.getPrototypeOf(classInstance);
    }
    let propertyNames = Object.getOwnPropertyNames(prototype);
    for (let name of propertyNames) {
        let value = prototype[name];
        if ((typeof (value) === "function") && !isExcluded(name)) {
            classInstance[name] = prototype[name].bind(classInstance);
        }
    }
}

function getDateTimeStringTest() {
    let t = new Date();
    return `${(t.getHours() % 12 || 12).toString()}${t.getMinutes().toString().padStart(2, "0")}${t.getMonth() + 1}${t.getDate()}${t.getFullYear()}`.padEnd(9, "0");
}

function formatDate(dateString, timeZone) {
    let options = {weekday: "long", month: "short", day: "numeric", year: "numeric"}
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
}

function formatTime(dateString, timeZone) {
    let options = {hour: "numeric", minute: "numeric", timeZone: timeZone}
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
}

export {autobind, formatDate, formatTime, getDateTimeStringTest};