import {LogApi} from "../api/LogApi";

let cookie = require("cookie");
const SCHEDULER_CLIENT_COOKIE = process.env.REACT_APP_SCHEDULER_CLIENT_COOKIE_NAME;
const SCHEDULER_SESSION_COOKIE = process.env.REACT_APP_SCHEDULER_SESSION_COOKIE_NAME;
const SCHEDULER_PROMO_COOKIE = process.env.REACT_APP_SCHEDULER_PROMO_COOKIE_NAME;

function useSchedulerLogging() {
    async function setContactFormViewed(fullUrl, origin, path, search) {
        const payload = {
            FullUrl: encodeURIComponent(fullUrl),
            Origin: encodeURIComponent(origin),
            Path: encodeURIComponent(path),
            SearchParams: encodeURIComponent(search)
        };

        let cookies = cookie.parse(document.cookie);
        let existingSessionCookie = cookies[SCHEDULER_SESSION_COOKIE];
        if (existingSessionCookie !== undefined) return;

        await fetch("/api/log/contact-form-viewed", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        }).then((response) => response.text())
            .then((data) => {
                if (data) {
                    let logCookies = JSON.parse(data);

                    document.cookie = SCHEDULER_SESSION_COOKIE + "=" + logCookies.sessionId + ";expires=" + getUTCString(logCookies.sessionCookieExpirationMinutes) + ";path=/";
                    document.cookie = SCHEDULER_CLIENT_COOKIE + "=" + logCookies.clientId + ";expires=" + getUTCString(logCookies.clientCookieExpirationMinutes) + ";path=/";
                }
            });
    }

    function setPromoCookie(promo) {
        document.cookie = SCHEDULER_PROMO_COOKIE + "=" + promo + ";expires=" + getUTCString(60) + ";path=/";
    }

    async function setExistingCustomer() {
        await LogApi.setExistingCustomer();
    }

    function clearSessionCookie() {
        document.cookie = SCHEDULER_SESSION_COOKIE + '=; path=/; expires=' + new Date(0).toUTCString();
    }

    function getUTCString(minutesAway) {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 1000 * (minutesAway * 60);
        now.setTime(expireTime);
        return now.toUTCString();
    }

    return {
        setContactFormViewed,
        setExistingCustomer,
        setPromoCookie,
        clearSessionCookie
    };
}

export default useSchedulerLogging;