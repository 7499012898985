import React, {useState} from "react";
import minusIcon from "../../images/accordion/minus.png";
import plusIcon from "../../images/accordion/plus.png";
import _uniqueId from 'lodash/uniqueId';
import $ from "jquery";

export default function AccordionRow({accordionId, title, iconUrl, bodyText, onCollapseExpanded, expandedCollapseId}) {
    let cardHeaderId = _uniqueId("heading-");
    const [collapseId] = useState(_uniqueId("collapse-"));

    const toggleIcon = () => {
        let className = $("#" + collapseId).attr('class');
        let isExpanded = className === "collapse";
        onCollapseExpanded(isExpanded ? collapseId : "");
    }

    return (
        <div className="card accordion-card">
            <div className="card-header accordion-card-header" id={cardHeaderId}>
                <button onClick={toggleIcon} className="btn btn-link accordion-link" type="button"
                        data-toggle="collapse"
                        data-target={"#" + collapseId} aria-expanded="true" aria-controls={collapseId}>
                    <div className="row">
                        <div className="col-10">
                            {title}
                        </div>
                        <div className="col-2">

                            <img src={(collapseId === expandedCollapseId) ? minusIcon : plusIcon}
                                 className="float-right accordion-expand-icon" alt="expand"/>
                        </div>
                    </div>
                </button>
            </div>

            <div id={collapseId} className="collapse" aria-labelledby={cardHeaderId}
                 data-parent={"#" + accordionId}>
                <div className="card-body accordion-body">
                    <p>
                        {iconUrl &&
                            <img
                                src={iconUrl}
                                alt="pspt-icon" width="100"
                                className="accordion-body-icon"/>
                        }
                        <span className="accordion-body-text">{bodyText}
                                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}