import React from "react";
import parse from 'html-react-parser';
import PawItem from "./PawItem";

function ImageContent({contentSrc, altText}) {
    return (
        <div>
            <img
                src={contentSrc}
                className="content-image"
                alt={altText} title="bullet-image"/>
        </div>
    )
}

function VideoContent({contentSrc, title}) {
    return (
        <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src={contentSrc}
                    title={title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
        </div>
    )
}

export default function LeftContent({contentType, contentSrc, imageAltText, headerText, bodyText, pawListItems}) {

    return (
        <div>
            {contentType === "image" && <ImageContent contentSrc={contentSrc} altText={imageAltText}/>}

            {contentType === "video" && <VideoContent contentSrc={contentSrc} title={imageAltText}/>}

            <div className="copy-container">
                <h1 className="content-header-text">{parse(headerText)}</h1>
                <p>{parse(bodyText)}</p>
                {pawListItems.length > 0 &&
                    pawListItems.map((pawText, idx) =>
                        <PawItem key={idx} text={pawText}></PawItem>
                    )
                }
            </div>
        </div>
    )
}