import React from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import "./paw-item.css";

export default function PawItem({text}) {

    return (
        <div className="mb-2">
            <span className="paw-container">
                <strong>
                    <img
                        className="paw-item"
                        src="https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=25&amp;name=paw_icon.png"
                        width="25" alt="Invisible Fence Brand"
                        srcSet="https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=13&amp;name=paw_icon.png 13w, https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=25&amp;name=paw_icon.png 25w, https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=38&amp;name=paw_icon.png 38w, https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=50&amp;name=paw_icon.png 50w, https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=63&amp;name=paw_icon.png 63w, https://info.invisiblefence.com/hs-fs/hubfs/Branding/Icons/Light%20Blue%20Pictographs/paw_icon.png?width=75&amp;name=paw_icon.png 75w"
                        sizes="(max-width: 25px) 100vw, 25px"/>
                    {text}
                    </strong>
            </span>
        </div>
    )
}