import {createSlice} from "@reduxjs/toolkit";
import DefaultLoadingScreen from "../components/loading/DefaultLoadingScreen";

const defaultLoadingScreen = DefaultLoadingScreen;

const loadingScreenState = {
    LoadingScreen: defaultLoadingScreen
};

let loadingScreenReducers = {
    setCustomLoadingScreen: (state, action) => {
        state.LoadingScreen = action.payload;
    },
    setDefaultLoadingScreen: (state, action) => {
        state.LoadingScreen = defaultLoadingScreen;
    }
}

const loadingScreenModule = createSlice({
    name: "loadingScreen",
    initialState: loadingScreenState,
    reducers: loadingScreenReducers
});

let asyncLoadingScreenActions = {
    setCustomLoadingScreen: (customScreen) => {
        return async (dispatch, getState) => {
            dispatch(loadingScreenModule.actions.setCustomLoadingScreen(customScreen));
        }
    },
    setDefaultLoadingScreen: () => {
        return async (dispatch, getState) => {
            dispatch(loadingScreenModule.actions.setDefaultLoadingScreen());
        }
    }
}

export const loadingScreenActions = {...loadingScreenModule.actions, ...asyncLoadingScreenActions};
export default loadingScreenModule.reducer;