import {v4 as uuidv4} from "uuid";
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function calendar(appointmentSlots) {
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();
    
    appointmentSlots = appointmentSlots || {};

    let calendar = {
        months: []
    };

    // build calendar out to 3 months
    for (let m = 0; m < 3; m++) {
        let targetMonth = currentMonth + m;
        let monthStart = new Date(currentYear, targetMonth, 1);
        let targetMonthTotalDays = new Date(currentYear, targetMonth + 1, 0).getDate();
        let prevMonthOffset = monthStart.getDay();
        let nextMonthOffset = (Math.ceil((targetMonthTotalDays + prevMonthOffset) / 7) * 7) - (targetMonthTotalDays + prevMonthOffset);

        let calendarEntry = {
            label: MONTHS[monthStart.getMonth()],
            index: m,
            days: []
        };

        //Left pad previous month days
        for (let x = prevMonthOffset; x > 0; x--) {
            let targetDate = new Date(currentYear, targetMonth, (x * -1) + 1);
            calendarEntry.days.push({
                class: "scheduler-adjacent-day",
                date: targetDate,
                day: targetDate.getDate()
            });
        }

        //Populate target month days
        for (let y = 0; y < targetMonthTotalDays; y++) {
            let targetDate = new Date(currentYear, targetMonth, y + 1);
            let dateKey = targetDate.toISOString().split("T")[0];
            calendarEntry.days.push({
                appointmentSlots: appointmentSlots.hasOwnProperty(dateKey) ? appointmentSlots[dateKey] : null,
                class: appointmentSlots.hasOwnProperty(dateKey) ? "scheduler-calendar-day with-slots" : "scheduler-calendar-day",
                id: uuidv4(),
                date: targetDate,
                day: targetDate.getDate()
            });
        }

        //Right pad next month days
        for (let z = 0; z < nextMonthOffset; z++) {
            let targetDate = new Date(currentYear, targetMonth, targetMonthTotalDays + z + 1);
            calendarEntry.days.push({
                class: "scheduler-adjacent-day",
                date: targetDate,
                day: targetDate.getDate()
            });
        }

        calendar.months.push(calendarEntry)
    }
    
    return calendar;
}