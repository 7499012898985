import React, {lazy, useEffect} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import VerifyAddressPage from "../../pages/service-scheduling/VerifyAddressPage";
import "bootstrap/dist/js/bootstrap.min.js";
import {useDispatch, useSelector} from "react-redux";
import {serviceActions, ServiceViews} from "../../redux-modules/ServiceModule";
import SystemTypePage from "../../pages/service-scheduling/SystemTypePage";
import FenceIssuePage from "../../pages/service-scheduling/FenceIssuePage";
import SystemDownPage from "../../pages/service-scheduling/SystemDownPage";
import AppointmentsPage from "../../pages/service-scheduling/AppointmentsPage";
import ReviewPage from "../../pages/service-scheduling/ReviewPage";
import ConfirmationPage from "../../pages/service-scheduling/ConfirmationPage";
import PetEscapingPage from "../../pages/service-scheduling/PetEscapingPage";
import TrainingPetTypePage from "../../pages/service-scheduling/TrainingPetTypePage";
import "./service-scheduler.css";
import SystemIssuePage from "../../pages/service-scheduling/SystemIssuePage";

const SchedulingErrorViewPage = lazy(() => import( "../../pages/service-scheduling/errors/SchedulingErrorViewPage"));

export default function ServiceScheduler(props) {
    const serviceState = useSelector(state => state.service);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (serviceState.currentView === ServiceViews.AppointmentList) {
            navigate("/appointments");
        } else {
            document.title = `${ServiceViews.BasePath.Title}${serviceState.currentView.Title}`;
            //if (serviceState.currentView !== ServiceViews.VerifyAddress) {
            navigate(`/${ServiceViews.BasePath.Path}/${serviceState.currentView.Path}`);
            //}            
        }

    }, [serviceState.currentView, dispatch])

    function goBack() {
        switch (serviceState.currentView) {
            case ServiceViews.VerifyAddress:
                dispatch(serviceActions.returnToAppointmentList());
                break;
            case ServiceViews.SystemType:
                dispatch(serviceActions.onSystemTypeBack());
                break;
            case ServiceViews.FenceIssue:
            case ServiceViews.SystemIssue:
                dispatch(serviceActions.showSystemTypePage());
                break;
            case ServiceViews.SystemDown:
            case ServiceViews.PetEscaping:
                dispatch(serviceActions.showFenceIssuePage());
                break;
            case ServiceViews.TrainingPetType:
                dispatch(serviceActions.showPetEscapingPage());
                break;
            case ServiceViews.Appointments:
                dispatch(serviceActions.onAppointmentsPageBack());
                break;
            case ServiceViews.Review:
                dispatch(serviceActions.onReviewFormBack());
                break;
            default:
            //Appointments
        }
    }

    return (
        <>
            {serviceState.currentView !== ServiceViews.Confirmation
                && serviceState.currentView !== ServiceViews.Errors.Appointments
                && serviceState.currentView !== ServiceViews.Errors.Schedule 
                && serviceState.currentView !== ServiceViews.OtherIssueConfirmation &&
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-12 text-left">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                goBack();
                            }}><p>
                                {serviceState.currentView === ServiceViews.Appointments ? "Start Over" : "Back"}
                            </p>
                            </a>
                        </div>
                    </div>
                </div>
            }


            <Routes>
                <Route exact path='/' element={<Navigate replace to={`${ServiceViews.VerifyAddress.Path}`}/>}/>
                <Route exact path={`/${ServiceViews.VerifyAddress.Path}`} element={
                    <VerifyAddressPage streetAddress={serviceState.contactAddress}
                                       onVerifyAddressNext={serviceActions.onVerifyAddressNext}/>
                }/>
                <Route exact path={`/${ServiceViews.SystemType.Path}`} element={
                    <SystemTypePage onSystemTypeSelection={serviceActions.onSystemTypeSelection}
                    />
                }/>
                <Route exact path={`/${ServiceViews.FenceIssue.Path}`} element={
                    <FenceIssuePage onFormSubmit={serviceActions.onFenceIssueFormSubmitted}
                    />
                }/>
                <Route exact path={`/${ServiceViews.SystemIssue.Path}`} element={                    
                    <SystemIssuePage systemName={serviceState.serviceRequest.systemType.system} onFormSubmit={serviceActions.onSystemIssueFormSubmitted}
                    />
                }/>
                <Route exact path={`/${ServiceViews.PetEscaping.Path}`} element={
                    <PetEscapingPage onIssueSelection={serviceActions.onPetEscapingSelection}/>
                }/>
                <Route exact path={`/${ServiceViews.TrainingPetType.Path}`} element={
                    <TrainingPetTypePage onPetTypeSelection={serviceActions.onTrainingPetTypeSelection}/>
                }/>
                <Route exact path={`/${ServiceViews.SystemDown.Path}`} element={
                    <SystemDownPage onFormSubmit={serviceActions.onSystemDownFormSubmitted}/>
                }/>
                <Route exact path={`/${ServiceViews.Appointments.Path}`} element={
                    <AppointmentsPage onDaySelected={serviceActions.daySelected}
                                      onPreviousMonthSelected={serviceActions.selectPreviousMonth}
                                      onNextMonthSelected={serviceActions.selectNextMonth}
                                      onSlotClick={serviceActions.onSlotClick}
                                      getSlots={serviceActions.getSlots}
                                      isSlotsLoaded={serviceState.appointmentsLoaded}/>
                }/>
                <Route exact path={`/${ServiceViews.Review.Path}`} element={
                    (
                        serviceState.workType != null && serviceState.selectedAppointment != null &&

                        <ReviewPage workTypeName={serviceState.workType.customerLabel}
                                    selectedAppointmentDate={serviceState.selectedAppointment.start}
                                    selectedAppointmentTime={serviceState.selectedAppointment.slotDisplay}
                                    onFormSubmit={serviceActions.onReviewFormSubmitted}
                                    onBack={serviceActions.onReviewFormBack}
                        />
                    )
                }/>
                <Route exact path={`/${ServiceViews.Confirmation.Path}`} element={
                    (serviceState.scheduledAppointment != null &&
                        <ConfirmationPage
                            apptId={serviceState.scheduledAppointment.id}
                            workType={serviceState.scheduledAppointment.workType}
                            appointmentDate={serviceState.scheduledAppointment.arrivalWindowStartTime}
                            slotDisplay={serviceState.scheduledAppointment.slotDisplay}
                            onReturnHome={serviceActions.returnToAppointmentList}
                        />
                    )
                }/>
                <Route exact path={`/${ServiceViews.OtherIssueConfirmation.Path}`} element={
                    <SchedulingErrorViewPage
                        onReturnHome={serviceActions.returnToAppointmentList}>
                        <span>A representative will be in touch to finish scheduling your appointment.</span>
                    </SchedulingErrorViewPage>
                }/>
                <Route exact path={`/${ServiceViews.Errors.Appointments.Path}`} element={
                    <SchedulingErrorViewPage
                        onReturnHome={serviceActions.returnToAppointmentList}></SchedulingErrorViewPage>
                }/>
                <Route exact path={`/${ServiceViews.Errors.Schedule.Path}`} element={
                    <SchedulingErrorViewPage
                        onReturnHome={serviceActions.returnToAppointmentList}>
                        <span>A representative will be in touch to finish scheduling your appointment.</span>
                    </SchedulingErrorViewPage>
                }/>
            </Routes>
        </>
    )

}