import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {ServiceViews} from "../../redux-modules/ServiceModule";

export default function FenceIssuePage({onFormSubmit}) {
    const dispatch = useDispatch();
    const service = useSelector(state => state.service);


    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setValue
    } = useForm();

    useEffect(() => {
        if (service.currentView === ServiceViews.FenceIssue) {
            setValue('systemIssueId', service.serviceRequest.systemIssueId.toString());
            setValue('comments', service.serviceRequest.comments);
        }
    }, [service.currentView]);

    const onSubmit = (data) => {
        dispatch(onFormSubmit({
            systemIssueId: parseInt(data.systemIssueId),
            comments: data.comments
        }));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12"><span className="scheduler-text-heading" style={{color: "#0067b1"}}>
                    What's Happening?
                </span></div>
            </div>
            <div className="row pl-3">
                <div className="col-12">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>Please select the issue you are experiencing<sup
                                className='text-red'>*</sup>:</label>
                            {errors.systemIssueId?.type === "required" && (
                                <p role="alert" className="text-red">Please select an option.</p>
                            )}
                            <div className="form-check pb-3">
                                <input {...register("systemIssueId", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="1"
                                       id="issue-broken"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="issue-broken" className="form-check-label pl-3">
                                    My wire is broken
                                </label>
                            </div>
                            <div className="form-check pb-3">
                                <input {...register("systemIssueId", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="2"
                                       id="issue-beeping"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="issue-beeping" className="form-check-label pl-3">
                                    My system is beeping
                                </label>
                            </div>
                            <div className="form-check pb-3">
                                <input {...register("systemIssueId", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="3"
                                       id="issue-escaping"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="issue-escaping" className="form-check-label pl-3">
                                    My pet is escaping
                                </label>
                            </div>
                            <div className="form-check pb-3">
                                <input {...register("systemIssueId", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="4"
                                       id="other"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="other" className="form-check-label pl-3">
                                    Other
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="">Please include any notes regarding your issue<sup
                                className="text-red">*</sup>:</label>
                            <br/>
                            <label className="small pl-4">Ex. wire break location, how long the issue has been occurring
                                etc.</label>
                            {errors.comments?.type === "required" && (
                                <p role="alert" className="text-red">Please describe your issue.</p>
                            )}
                            <textarea {...register("comments", {required: true})}
                                      rows="5"
                                      className="contact-form-input form-control"
                            />
                        </div>


                        <input type="submit" value="Next" className="contact-form-submit-btn float-left"/>
                    </form>
                </div>
            </div>

            <div className="row pt-3">
                <div className="col-12">
                    <h5>Don't see what you need? Give us a call at <a href="tel:8008243647">(800)
                        824-3647.</a></h5>
                </div>
            </div>
        </div>
    )
}