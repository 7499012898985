import {createSlice} from "@reduxjs/toolkit";

const modalState = {
    info: {
        visible: false,
        text: null
    },
    error: {
        visible: false,
        text: null
    },
    confirm: {
        visible: false,
        action: null,
        text: null
    }
}

let modalReducers = {
    showInfo: (state, action) => {
      state.info.visible = true;
      state.info.text = action.payload;
    },
    hideInfo: (state, action) => {
        state.info.visible = false;
    },
    showError: (state, action) => {
        state.error.visible = true;
        state.error.text = action.payload;
    },
    hideError: (state, action) => {
        state.error.visible = false;
    },
    showConfirm: (state, action) =>{
        state.confirm.visible = true;
        state.confirm.text = action.payload.text;
        state.confirm.action = action.payload.action;
    },
    hideConfirm(state, action){
        state.confirm.visible = false;
    }
}

const modalModule = createSlice({
    name: "modals",
    initialState: modalState,
    reducers: modalReducers
});

export const modalActions = modalModule.actions;
export default modalModule.reducer;