import $ from "jquery";

export class CrmApi {

    static sendEmailRequest(request) {
        return $.ajax("api/dealer/crm/submission", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(request)
        });
    }

    static getAppointments(request) {
        return $.ajax("api/dealer/crm/appointments", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(request)
        });
    }

    static scheduleAppointment(slot) {
        return $.ajax("/api/dealer/crm/schedule", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(slot)
        });
    }
}