import {createSlice} from "@reduxjs/toolkit";
import buildCalendar from "../calendar/calendar";
import {ProspectApi} from "../api/ProspectApi";
import {modalActions} from "./ModalModule";
import {formatDate} from "../utility/utilities";
import {EFenceApi} from "../api/EFenceApi";
import {CrmApi} from "../api/CrmApi";
import {DealerApi} from "../api/DealerApi";
import {LogApi} from "../api/LogApi";
import AppointmentLoadingScreen from "../components/prospect-scheduling/AppointmentLoadingScreen";
import {loadingScreenActions} from "./LoadingScreenModule";

let initialCalendar = buildCalendar();

export const ProspectViews = {
    BasePath: {
        Path: 'prospect',
        Title: "Consultation Scheduler - "
    },
    RedirectHandler: {
        Path: 'redirect'
    },
    WebAssistHandler: {
        Path: 'web-assist'
    },
    ContactForm: {
        Path: 'contact',
        Title: "Contact Form"
    },
    DealerSelection: {
        Path: 'dealer-selection',
        Title: "Dealer selection"
    },
    NoDealers: {
        Path: 'zip-not-found',
        Title: "Zip Not Found"
    },
    Errors: {
        Recaptcha: {
            Path: 'e/rc',
            Title: "E: RC"
        },
        Dealers: {
            Path: 'e/dealers',
            Title: "E: Dealers"
        },
    },
    CRM: {
        BasePath: {
            Path: 'crm',
            Title: "CRM Scheduler - "
        },
        ExistingCustomer: {
            Path: 'crm/existing-customer',
            LocalPath: 'existing-customer',
            Title: "Existing Customer (crm)"
        },
        EmailSubmitted: {
            Path: 'crm/submission',
            LocalPath: 'submission',
            Title: "CRM Submission"
        },
        Appointments: {
            Path: 'crm/appointments',
            LocalPath: 'appointments',
            Title: "Appointment Selection (crm)"
        },
        Confirmation: {
            Path: 'crm/confirmation',
            LocalPath: 'confirmation',
            Title: "Confirmation"
        },
        Errors: {
            Appointments: {
                Path: 'crm/e/appointments',
                LocalPath: 'e/appointments',
                Title: "E: Appointment Selection (CRM)"
            },
            Schedule: {
                Path: 'crm/e/schedule',
                LocalPath: 'e/schedule',
                Title: "E: Schedule (CRM)"
            }
        }
    },
    EFence: {
        BasePath: {
            Path: 'efence',
            Title: "EFence Scheduler - "
        },
        Appointments: {
            Path: 'efence/appointments',
            LocalPath: 'appointments',
            Title: "Appointment Selection (EFence)"
        },
        Confirmation: {
            Path: 'efence/confirmation',
            LocalPath: 'confirmation',
            Title: "Confirmation"
        },
        Errors: {
            Appointments: {
                Path: 'efence/e/appointments',
                LocalPath: 'e/appointments',
                Title: "E: Appointment Selection (EFence)"
            },
            Schedule: {
                Path: 'efence/e/schedule',
                LocalPath: 'e/schedule',
                Title: "E: Schedule (EFence)"
            }
        }
    },
    Email: {
        Confirmation: {
            Path: "dealer-submission",
            Title: "Dealer Submission"
        },
        Error: {
            Path: "e/dealer-submission",
            Title: "E: Dealer Submission"
        }
    },
    COD: {
        ExistingCustomer: {
            Path: 'existing-customer',
            Title: "Existing Customer",            
        },
        ExistingCustomer_OtherIssue: {
            Path: 'existing-customer_other-issue',
            Title: "Existing Customer - Other Issue",
        },
        ExistingCustomer_RemoteConsultCreated: {
            Path: 'existing-customer_remote-consult',
            Title: "Existing Customer - Remote Consult",
        },
        ConsultType: {
            Path: 'consult-type',
            Title: "Consult Type Selection"
        },
        Appointments: {
            Path: 'appointments',
            Title: "Appointment Selection"
        },
        Confirmation: {
            Path: 'confirmation',
            Title: "Confirmation"
        },
        Errors: {
            ContactForm: {
                Path: 'e/contact-form',
                Title: "E: Contact Form"
            },
            Appointments: {
                Path: 'e/appointments',
                Title: "E: Appointment Selection"
            },
            NoWorkTypes: {
                Path: 'e/work-types',
                Title: "E: Work Types"
            },
            Schedule: {
                Path: 'e/schedule',
                Title: "E: Schedule"
            }
        }
    }
}

const prospectState = {
    currentView: ProspectViews.ContactForm,
    zip: "",
    city: "",
    state: "",
    dealers: [],
    selectedDealer: null,
    workTypes: [],
    defaultConsultWorkTypeId: "",
    contactForm: null,
    customerRequest: null,
    recaptchaToken: "",
    contact: {
        contactId: null,
        isExistingCustomer: false
    },
    error: null,
    selectedWorkTypeId: null,
    appointments: [],
    reloadAppointments: false,
    appointmentsLoaded: false,
    calendar: initialCalendar,
    selectedMonth: initialCalendar.months[0],
    selectedDay: null,
    loadLastCalendarDate: false,
    scheduledSlot: {
        slot: null,
        selectedWorkType: null
    },
    referralEmailSaved: false
};

let prospectReducers = {
    setContactForm: (state, action) => {
        state.contactForm = action.payload;
    },
    setCustomerRequest: (state, action) => {
        state.customerRequest = action.payload;
    },
    setRecaptchaToken: (state, action) => {
        state.recaptchaToken = action.payload;
    },
    setDealers: (state, action) => {
        state.dealers = action.payload;
        state.currentView = ProspectViews.DealerSelection;
    },
    setSelectedDealer: (state, action) => {
        state.selectedDealer = action.payload;
    },
    dealersError: (state, action) => {
        state.currentView = ProspectViews.Errors.Dealers;
    },
    setNoDealers: (state, action) => {
        state.currentView = ProspectViews.NoDealers;
    },

    showContactForm: (state, action) => {
        state.currentView = ProspectViews.ContactForm;
    },

    recaptchaError: (state, action) => {
        state.currentView = ProspectViews.Errors.Recaptcha;
    },

    //COD
    COD_contactFormError: (state, action) => {
        state.currentView = ProspectViews.COD.Errors.ContactForm;
    },
    COD_setContact: (state, action) => {
        state.contact = action.payload;

        if (state.contact.isExistingCustomer) {
            state.currentView = ProspectViews.COD.ExistingCustomer;
        }
    },
    COD_setWorkTypes: (state, action) => {
        state.zip = action.payload.zipCode;
        state.city = action.payload.city;
        state.state = action.payload.stateCode;
        state.workTypes = action.payload.workTypes;
        state.defaultConsultWorkTypeId = action.payload.defaultConsultWorkTypeId;
        
        if (state.workTypes != null && state.workTypes.length > 0) {
            state.selectedWorkTypeId = state.workTypes[0].id;
        }
        
        if (state.workTypes.length === 2 && state.defaultConsultWorkTypeId != "") {
            let selectedWorkType = state.workTypes.find(x => x.id === state.defaultConsultWorkTypeId);
            
            if (selectedWorkType != null) {
                state.selectedWorkTypeId = selectedWorkType.id;
            } else {
                state.selectedWorkTypeId = state.workTypes[0].id;
            }
        }

        state.currentView = state.selectedWorkTypeId === null ? ProspectViews.COD.ConsultType : ProspectViews.COD.Appointments;

    },
    COD_appointmentScheduled: (state, action) => {
        state.scheduledSlot.slot = action.payload;
        state.currentView = ProspectViews.COD.Confirmation;
    },
    COD_existingCustomer_remoteConsultCreated: (state, action) => {
        state.currentView = ProspectViews.COD.ExistingCustomer_RemoteConsultCreated;
    },
    COD_existingCustomer_otherIssue: (state, action) => {
      state.currentView = ProspectViews.COD.ExistingCustomer_OtherIssue;  
    },
    COD_setNoWorkTypes: (state, action) => {
        state.currentView = ProspectViews.COD.Errors.NoWorkTypes;
    },
    COD_setSelectedWorkTypeId: (state, action) => {
        state.selectedWorkTypeId = action.payload;
    },
    COD_appointmentSlotsError: (state, action) => {
        state.currentView = ProspectViews.COD.Errors.Appointments;
    },
    COD_scheduleAppointmentError: (state, action) => {
        state.currentView = ProspectViews.COD.Errors.Schedule;
    },

    COD_setReferralEmailSaved: (state, action) => {
        state.referralEmailSaved = true;
    },

    //CRM (Independent Dealers)
    CRM_Appointments: (state, action) => {
        state.currentView = ProspectViews.CRM.Appointments;
    },

    CRM_EmailSubmitted: (state, action) => {
        state.currentView = ProspectViews.CRM.EmailSubmitted;
    },

    CRM_emailSubmissionError: (state, action) => {
        state.currentView = ProspectViews.Email.Error;
    },

    CRM_appointmentSlotsError: (state, action) => {
        state.currentView = ProspectViews.CRM.Errors.Appointments;
    },

    CRM_ExistingCustomer: (state, action) => {
        state.currentView = ProspectViews.CRM.ExistingCustomer;
    },

    CRM_scheduleAppointmentError: (state, action) => {
        state.currentView = ProspectViews.CRM.Errors.Schedule;
    },

    CRM_appointmentScheduled: (state, action) => {
        state.scheduledSlot.slot = action.payload;
        state.currentView = ProspectViews.CRM.Confirmation;
    },

    //EFence (Independent Dealers)
    EFence_Appointments: (state, action) => {
        state.currentView = ProspectViews.EFence.Appointments;
    },

    EFence_appointmentSlotsError: (state, action) => {
        state.currentView = ProspectViews.EFence.Errors.Appointments;
    },

    EFence_scheduleAppointmentError: (state, action) => {
        state.currentView = ProspectViews.EFence.Errors.Schedule;
    },

    EFence_appointmentScheduled: (state, action) => {
        state.scheduledSlot.slot = action.payload;
        state.currentView = ProspectViews.EFence.Confirmation;
    },

    //Email (Independent Dealers)
    Email_confirmation: (state, action) => {
        state.currentView = ProspectViews.Email.Confirmation;
    },

    Email_error: (state, action) => {
        state.currentView = ProspectViews.Email.Error;
    },

    //Appointment Calendar
    appointmentSlotsLoaded: (state, action) => {

        state.zip = action.payload.workTypesResponse.zipCode;
        state.city = action.payload.workTypesResponse.city;
        state.state = action.payload.workTypesResponse.stateCode;
        state.workTypes = action.payload.workTypesResponse.workTypes;
        state.defaultConsultWorkTypeId = action.payload.workTypesResponse.defaultConsultWorkTypeId;

        if (state.workTypes != null && state.workTypes.length > 0) {
            state.selectedWorkTypeId = state.workTypes[0].id;
        }

        if (state.workTypes.length === 2 && state.defaultConsultWorkTypeId != "") {
            let selectedWorkType = state.workTypes.find(x => x.id === state.defaultConsultWorkTypeId);

            if (selectedWorkType != null) {
                state.selectedWorkTypeId = selectedWorkType.id;
            } else {
                state.selectedWorkTypeId = state.workTypes[0].id;
            }
        }
        
        state.calendar = buildCalendar(action.payload.slots);
        state.selectedMonth = state.calendar.months[0];
        state.appointmentsLoaded = true;
    },
    EFence_appointmentSlotsLoaded: (state, action) => {
        state.calendar = buildCalendar(action.payload);
        state.selectedMonth = state.calendar.months[0];
        state.appointmentsLoaded = true;
    },
    daySelected: (state, action) => {
        state.selectedDay = action.payload;
    },
    selectNextMonth: (state, action) => {
        let index = state.selectedMonth.index + 1;
        if (index < state.calendar.months.length) {
            if (state.calendar.months[index].days.filter(x => x.hasOwnProperty("appointmentSlots") && x.appointmentSlots !== null).length > 0) {
                state.loadLastCalendarDate = false;
                state.selectedMonth = state.calendar.months[index];
            }
        }
    },
    selectPreviousMonth: (state, action) => {
        let index = state.selectedMonth.index - 1;
        if (index >= 0) {
            if (state.calendar.months[index].days.filter(x => x.hasOwnProperty("appointmentSlots") && x.appointmentSlots !== null && x.appointmentSlots.filter(x => x.workTypeId === state.selectedWorkTypeId).length > 0).length > 0) {
                state.loadLastCalendarDate = action.payload;
                state.selectedMonth = state.calendar.months[index];
            }
        }
    },
    selectNextMonth_EFence: (state, action) => {
        let index = state.selectedMonth.index + 1;
        if (index < state.calendar.months.length)
            state.selectedMonth = state.calendar.months[index];        
    },
    selectPreviousMonth_EFence: (state, action) => {
        let index = state.selectedMonth.index - 1;
        if (index >= 0) 
            state.selectedMonth = state.calendar.months[index];
    },

    clearState: (state, action) => {
        state.zip = "";
        state.city = "";
        state.state = "";
        state.dealers = [];
        state.selectedDealer = null;
        state.workTypes = [];
        state.defaultConsultWorkTypeId = "";
        state.contactForm = null;
        state.recaptchaToken = "";
        state.contact = {
            contactId: null,
            isExistingCustomer: false
        };
        state.error = null;
        state.selectedWorkTypeId = null;
        state.appointments = [];
        state.reloadAppointments = false;
        state.appointmentsLoaded = false;
        state.calendar = initialCalendar;
        state.selectedMonth = initialCalendar.months[0];
        state.selectedDay = null;
        state.referralEmailSaved = false;
        state.loadLastCalendarDate = false;
    }
};

const prospectModule = createSlice({
    name: "prospect",
    initialState: prospectState,
    reducers: prospectReducers
});

let asyncProspectActions = {
    showContactForm: () => {
        return async (dispatch, getState) => {
            dispatch(prospectModule.actions.showContactForm());
        }
    },
    onContactFormSubmit: (contactForm, recaptchaToken) => {
        return async (dispatch, getState) => {
            let request = {
                firstName: contactForm.firstName,
                lastName: contactForm.lastName,
                email: contactForm.email,
                phone: contactForm.phoneNumber,
                mailingStreet: contactForm.address,
                mailingCity: contactForm.city,
                mailingPostalCode: contactForm.zip,
                mailingStateCode: contactForm.state,
                mailingCountryCode: "",
                marketingPromo__c: contactForm.promo
            };

            try {
                let leadResponse = await ProspectApi.captureLead(request, recaptchaToken);
                dispatch(prospectModule.actions.setContactForm(leadResponse));
            } catch (ex) {
                if (ex.status === 406) {
                    dispatch(prospectModule.actions.recaptchaError());
                    return;
                }
            }

            dispatch(prospectModule.actions.setRecaptchaToken(recaptchaToken));

            dispatch(prospectActions.getDealers());

        }
    },
    getDealers: () => {
        return async (dispatch, getState) => {
            try {
                let contactForm = getState().prospect.contactForm;
                let dealers = await ProspectApi.getDealersForContact(contactForm);

                if (dealers === null || dealers.length === 0) {
                    dispatch(prospectModule.actions.setNoDealers());
                    return;
                }

                if (dealers.length > 1) {

                    if (contactForm.marketingPromo__c !== null && contactForm.marketingPromo__c.includes("PPC_COD")) {
                        let codDealers = dealers.filter(x => x.isCod);
                        if (codDealers.length > 0) {
                            dispatch(prospectModule.actions.setSelectedDealer(codDealers[0]));
                            dispatch(prospectActions.onDealerSelection(codDealers[0]));
                            return;
                        }
                    }

                    dispatch(prospectModule.actions.setDealers(dealers));

                } else {
                    dispatch(prospectModule.actions.setSelectedDealer(dealers[0]));
                    dispatch(prospectActions.onDealerSelection(dealers[0]));
                }

            } catch (ex) {
                console.log(ex);

                if (ex.status === 417) {
                    dispatch(prospectModule.actions.setNoDealers());
                } else {
                    dispatch(prospectModule.actions.dealersError());
                }
            }
        }
    },
    onDealerSelection: (dealer) => {
        return async (dispatch, getState) => {
            dispatch(prospectModule.actions.setSelectedDealer(dealer));

            await LogApi.setDealerSelected(dealer);

            if (dealer.isCod) {
                dispatch(prospectActions.COD.onUpsertCustomer());
                return;
            }

            if (dealer.isEmail) {
                dispatch(prospectActions.Email.onEmailSubmission());
                return;
            }

            if (dealer.isEFence) {
                dispatch(prospectActions.EFence.initScheduler());
                return;
            }

            if (dealer.isCrm) {
                dispatch(prospectActions.CRM.sendEmail());
                //dispatch(prospectModule.actions.CRM_Appointments());
            }

        }
    },
    CRM: {
        sendEmail: () => {
            return async (dispatch, getState) => {
                try {
                    let contactForm = getState().prospect.contactForm;
                    let selectedDealer = getState().prospect.selectedDealer;

                    let request = {
                        firstName: contactForm.firstName,
                        lastName: contactForm.lastName,
                        email: contactForm.email,
                        phoneNumber: contactForm.phone,
                        address: contactForm.mailingStreet,
                        city: contactForm.mailingCity,
                        zip: contactForm.mailingPostalCode,
                        state: contactForm.mailingStateCode,
                        country: contactForm.mailingCountryCode,
                        promo: contactForm.marketingPromo__c,
                        dealerId: selectedDealer.dealerId,
                        blueDealerId: selectedDealer.blueDealerId
                    };

                    let getAppointmentsResponse = await CrmApi.sendEmailRequest(request);
                    document.cookie = process.env.REACT_APP_SCHEDULER_SESSION_COOKIE_NAME + '=; path=/; expires=' + new Date(0).toUTCString();
                    dispatch(prospectModule.actions.CRM_EmailSubmitted());

                } catch (ex) {
                    console.error(ex);
                    dispatch(prospectModule.actions.CRM_emailSubmissionError())
                }


            }
        },
        getSlots: () => {
            return async (dispatch, getState) => {
                try {
                    let contactForm = getState().prospect.contactForm;
                    let request = {
                        firstName: contactForm.firstName,
                        lastName: contactForm.lastName,
                        emailAddress: contactForm.email,
                        phoneNumber: contactForm.phone,
                        address: contactForm.mailingStreet,
                        city: contactForm.mailingCity,
                        zip: contactForm.mailingPostalCode,
                        state: contactForm.mailingStateCode,
                        promo: contactForm.marketingPromo__c
                    };

                    dispatch(prospectModule.actions.setCustomerRequest(request));

                    dispatch(loadingScreenActions.setCustomLoadingScreen(AppointmentLoadingScreen));

                    let getAppointmentsResponse = await CrmApi.getAppointments(request);

                    dispatch(loadingScreenActions.setDefaultLoadingScreen());

                    let slots = getAppointmentsResponse.appointments;

                    slots = slots || {};

                    if (slots == null) {
                        dispatch(prospectModule.actions.CRM_appointmentSlotsError());
                    }

                    dispatch(prospectModule.actions.setCustomerRequest(getAppointmentsResponse.customer));

                    dispatch(prospectModule.actions.appointmentSlotsLoaded(slots));
                } catch (ex) {
                    if (ex.status === 409) {
                        dispatch(prospectModule.actions.CRM_ExistingCustomer());
                    } else {
                        dispatch(prospectModule.actions.CRM_appointmentSlotsError());
                    }
                }
            }
        },
        onSlotClick: (slot) => {
            return async (dispatch, getState) => {

                let payload = {
                    text: `Are you sure you want to schedule your consultation for ${formatDate(slot.start)} with appointment window ${slot.slotDisplay}?`,
                    action: () => {
                        dispatch(prospectActions.CRM.scheduleAppointment(slot));
                    }
                }
                dispatch(modalActions.showConfirm(payload));
            }
        },
        scheduleAppointment: (slot) => {
            return async (dispatch, getState) => {
                try {
                    let customerRequest = getState().prospect.customerRequest;

                    let request = {
                        customer: customerRequest,
                        appointment: slot
                    };

                    let response = await CrmApi.scheduleAppointment(request);
                    if (response) {
                        dispatch(prospectModule.actions.CRM_appointmentScheduled(slot));
                        dispatch(prospectModule.actions.clearState());
                    } else {
                        dispatch(prospectModule.actions.CRM_scheduleAppointmentError());
                    }
                } catch (ex) {
                    console.error(ex);
                    if (ex.status === 417) {
                        //Appointment Taken
                        dispatch(modalActions.showInfo("We're sorry, but the appointment you selected is no longer available.  Please select a new date and time."));
                        dispatch(prospectActions.CRM.getSlots());
                    } else {
                        dispatch(prospectModule.actions.CRM_scheduleAppointmentError());
                    }
                }
            }
        }
    },
    EFence: {
        initScheduler: () => {
            return async (dispatch, getState) => {
                dispatch(prospectModule.actions.EFence_Appointments());
            }
        },
        getSlots: () => {
            return async (dispatch, getState) => {
                try {
                    let contactForm = getState().prospect.contactForm;
                    let selectedDealer = getState().prospect.selectedDealer;

                    let request = {
                        firstName: contactForm.firstName,
                        lastName: contactForm.lastName,
                        emailAddress: contactForm.email,
                        phoneNumber: contactForm.phone,
                        address: contactForm.mailingStreet,
                        city: contactForm.mailingCity,
                        zip: contactForm.mailingPostalCode,
                        state: contactForm.mailingStateCode,
                        country: contactForm.mailingCountryCode,
                        promotion: contactForm.marketingPromo__c,
                        dealerId: selectedDealer.dealerId,
                        blueDealerId: selectedDealer.blueDealerId
                    };

                    dispatch(loadingScreenActions.setCustomLoadingScreen(AppointmentLoadingScreen));

                    let getAppointmentsResponse = await EFenceApi.getAppointments(request);

                    dispatch(loadingScreenActions.setDefaultLoadingScreen());

                    let slots = getAppointmentsResponse.appointments;

                    slots = slots || {};

                    if (slots == null) {
                        dispatch(prospectModule.actions.EFence_appointmentSlotsError());
                    }

                    dispatch(prospectModule.actions.setCustomerRequest(getAppointmentsResponse.customer));

                    dispatch(prospectModule.actions.EFence_appointmentSlotsLoaded(slots));
                } catch (ex) {
                    dispatch(prospectModule.actions.EFence_appointmentSlotsError());
                }
            }
        },
        onSlotClick: (slot) => {
            return async (dispatch, getState) => {

                let payload = {
                    text: `Are you sure you want to schedule your consultation for ${formatDate(slot.start)} with appointment window ${slot.slotDisplay}?`,
                    action: () => {
                        dispatch(prospectActions.EFence.scheduleAppointment(slot));
                    }
                }
                dispatch(modalActions.showConfirm(payload));
            }
        },
        scheduleAppointment: (slot) => {
            return async (dispatch, getState) => {
                try {
                    let customerRequest = getState().prospect.customerRequest;

                    let request = {
                        customer: customerRequest,
                        appointment: slot
                    };

                    let response = await EFenceApi.scheduleAppointment(request);
                    if (response) {
                        dispatch(prospectModule.actions.EFence_appointmentScheduled(slot));
                        dispatch(prospectModule.actions.clearState());
                    } else {
                        dispatch(prospectModule.actions.EFence_scheduleAppointmentError());
                    }
                } catch (ex) {
                    console.error(ex);
                    if (ex.status === 417) {
                        //Appointment Taken
                        dispatch(modalActions.showInfo("We're sorry, but the appointment you selected is no longer available.  Please select a new date and time."));
                        dispatch(prospectActions.EFence.getSlots());
                    } else {
                        dispatch(prospectModule.actions.EFence_scheduleAppointmentError());
                    }
                }
            }
        }
    },
    Email: {
        onEmailSubmission: () => {
            return async (dispatch, getState) => {
                try {
                    let contactForm = getState().prospect.contactForm;
                    let selectedDealer = getState().prospect.selectedDealer;

                    let request = {
                        firstName: contactForm.firstName,
                        lastName: contactForm.lastName,
                        email: contactForm.email,
                        phoneNumber: contactForm.phone,
                        address: contactForm.mailingStreet,
                        city: contactForm.mailingCity,
                        zip: contactForm.mailingPostalCode,
                        country: contactForm.mailingCountryCode,
                        state: contactForm.mailingStateCode,
                        promo: contactForm.marketingPromo__c,
                        dealerId: selectedDealer.dealerId,
                        blueDealerId: selectedDealer.blueDealerId
                    };

                    let response = await DealerApi.sendDealerEmails(request);
                    document.cookie = process.env.REACT_APP_SCHEDULER_SESSION_COOKIE_NAME + '=; path=/; expires=' + new Date(0).toUTCString();
                    dispatch(prospectModule.actions.Email_confirmation());

                } catch (ex) {
                    console.error(ex);
                    dispatch(prospectModule.actions.Email_error())
                }
            }
        }
    },

    COD: {
        onUpsertCustomer: () => {
            return async (dispatch, getState) => {
                try {
                    let contactForm = getState().prospect.contactForm;
                    let custResponse = await ProspectApi.upsertCustomer(contactForm, getState().prospect.recaptchaToken);

                    dispatch(prospectModule.actions.COD_setContact(custResponse));

                    if (!custResponse.isExistingCustomer)
                        dispatch(prospectActions.COD.getWorkTypes(custResponse.contactId, contactForm.mailingPostalCode));
                } catch (ex) {
                    console.log(ex);
                    dispatch(prospectModule.actions.COD_contactFormError());
                }
            }
        },
        getWorkTypes: (contactId, zipCode) => {
            return async (dispatch, getState) => {
                try {

                    let workTypesResponse = await ProspectApi.getWorkTypes(contactId, zipCode);

                    if (workTypesResponse == null
                        || workTypesResponse.city == null
                        || workTypesResponse.workTypes == null
                        || workTypesResponse.workTypes.length === 0) {
                        dispatch(prospectModule.actions.COD_setNoWorkTypes());
                        return;
                    }

                    dispatch(prospectModule.actions.COD_setWorkTypes(workTypesResponse));

                } catch (ex) {
                    dispatch(prospectModule.actions.COD_setNoWorkTypes());
                }
            }
        },
        onExistingCustomerOtherIssueSelected: () => {
          return async (dispatch, getState) => {
              dispatch(prospectModule.actions.COD_existingCustomer_otherIssue());
          }  
        },
        getSlots: () => {
            return async (dispatch, getState) => {
                try {

                    dispatch(loadingScreenActions.setCustomLoadingScreen(AppointmentLoadingScreen));

                    let slotResponse = await ProspectApi.getConsultAppointments(getState().prospect.contact.contactId);
                    dispatch(loadingScreenActions.setDefaultLoadingScreen());

                    slotResponse.slots = slotResponse.slots || {};

                    if (slotResponse.slots == null) {
                        dispatch(prospectModule.actions.COD_appointmentSlotsError());
                    }

                    dispatch(prospectModule.actions.appointmentSlotsLoaded(slotResponse));
                } catch (ex) {
                    dispatch(prospectModule.actions.COD_appointmentSlotsError());
                }
            }
        },
        onSlotClick: (slot) => {
            return async (dispatch, getState) => {

                let slotWorkType = getState().prospect.workTypes.find(x => x.id === slot.workTypeId);
                let payload = {
                    text: `Confirm ${slotWorkType.customerLabel.toLowerCase()} consultation for ${formatDate(slot.start)} ${slot.exactAppointment ? "at" : "with an arrival window of"} ${slot.slotDisplay}?<br/><br/><i>We will send you an email one day prior to your appointment with additional details.</i>`,
                    action: () => {
                        dispatch(prospectActions.COD.scheduleAppointment(slot));
                    }
                }
                dispatch(modalActions.showConfirm(payload));
            }
        },
        scheduleAppointment: (slot) => {
            return async (dispatch, getState) => {
                try {
                    let response = await ProspectApi.scheduleAppointment(slot);
                    dispatch(prospectModule.actions.COD_appointmentScheduled(response));
                    dispatch(prospectModule.actions.clearState());
                } catch (ex) {
                    console.error(ex);
                    dispatch(prospectModule.actions.COD_scheduleAppointmentError());
                }
            }
        },
        saveReferralEmail: (contactId, email) => {
            return async (dispatch, getState) => {
                let response = await ProspectApi.saveReferralEmail(contactId, email);
                dispatch(prospectModule.actions.COD_setReferralEmailSaved());
            }
        },
        onMovedOrMajorLayout: (contactId) => {
            return async (dispatch, getState) => {
                let response = await ProspectApi.createRemoteConsult(contactId);
                dispatch(prospectModule.actions.COD_existingCustomer_remoteConsultCreated());
            }
        }
    },

}

export const prospectActions = {...prospectModule.actions, ...asyncProspectActions}
export default prospectModule.reducer;