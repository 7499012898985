import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {schedulingActions} from "../../redux-modules/SchedulingModule";
import {modalActions} from "../../redux-modules/ModalModule";
import {formatDate} from "../../utility/utilities";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js"
import {userActions} from "../../redux-modules/UserModule";
import {serviceActions, ServiceViews} from "../../redux-modules/ServiceModule";

export default function AppointmentDashboardPage(props) {
    const appointments = useSelector(state => state.scheduling.appointments);
    const serviceState = useSelector(state => state.service);
    const reload = useSelector(state => state.scheduling.reloadAppointments);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.authenticated) {
            
            if (reload) {
                dispatch(schedulingActions.getAppointments());
            }
            
            if (user.givenName === null)
                dispatch(userActions.getGivenName());
        }
        dispatch(schedulingActions.setAppointmentDashboardPage());
    }, [user.authenticated, reload, dispatch]);

    useEffect(() => {
        $("[data-toggle='popover']").popover({trigger: "focus"});
    }, [appointments]);

    const handleAppointmentCancel = (appointmentId) => {
        let payload = {
            text: "Are you sure you want to cancel this appointment?",
            action: () => {
                dispatch(schedulingActions.cancelAppointment(appointmentId))
            }
        }
        dispatch(modalActions.showConfirm(payload));
    }
    const handleAppointmentReschedule = (appointmentId) => {
        //dispatch(schedulingActions.setAppointmentId(appointmentId));
        navigate("/appointments/" + appointmentId + "/reschedule");
    }
    const handleNewAppointment = () => {
        dispatch(serviceActions.showVerifyAddressPage());
        navigate(`/${ServiceViews.BasePath.Path}`);
    }
    const handleLogout = () => {
        dispatch(userActions.logout());
    }
    return (
        <div className="mx-3">
            <header className="scheduler-dashboard-header">
                <section className="mb-4 scheduler-greeting">
                    <h1>Welcome, {user.givenName}!</h1>
                    <p className="scheduler-link ml-3" onClick={handleLogout}>(Use a different email?)</p>
                </section>
                <section className="mb-4 scheduler-title">
                    <h2>Upcoming Appointments</h2>
                </section>
            </header>
            <div className="scheduler-dashboard-body">
                {
                    appointments.length > 0 ? null :
                        <div className="card">
                            <span className="p-3 scheduler-text-heading" style={{color: "#0067b1"}}>
                            You don't have any upcoming appointments!
                            </span>
                        </div>

                }
                {
                    appointments.map((appointment, index) => {
                        return (
                            <div className="card p-3 mb-3" key={appointment.id}>
                                <div className="row mb-3">
                                    <div className="col-sm">
                                      <span className="my-3 scheduler-text-heading" style={{color: "#0067b1"}}>
                                          {
                                              formatDate(appointment.arrivalWindowStart)
                                          }
                                      </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <span
                                            className="my-3 mr-3 scheduler-text-body">{appointment.appointmentType}</span>
                                    </div>
                                    <div className="col-sm">
                                        <p className="scheduler-text-body">{appointment.arrivalWindow}</p>
                                    </div>
                                    <div className="col-sm">
                                        {
                                            process.env.REACT_APP_SHOW_CANCEL_APPOINTMENT === 'true' &&
                                            (appointment.isEditable ?
                                                <button type="button"
                                                        className="p-2 scheduler-button scheduler-text-body"
                                                        onClick={() => handleAppointmentCancel(appointment.id)}>
                                                    Cancel
                                                </button> :
                                                <a className="p-2 btn scheduler-button scheduler-button-disabled scheduler-text-body"
                                                   tabIndex="0" data-container="body" data-toggle="popover"
                                                   data-placement="top"
                                                   data-content="This appointment is less than 24 hours away or requires additional information to modify. Please call 1 (800) 824-3647 to change this appointment.">
                                                    Cancel
                                                </a>
                                            )
                                        }
                                    </div>
                                    <div className="col-sm">
                                        {
                                            appointment.isEditable ?
                                                <button type="button"
                                                        className="p-2 scheduler-button scheduler-text-body"
                                                        onClick={() => handleAppointmentReschedule(appointment.id)}>
                                                    Reschedule
                                                </button> :
                                                <a className="p-2 btn scheduler-button scheduler-button-disabled scheduler-text-body"
                                                   tabIndex="0" data-container="body" data-toggle="popover"
                                                   data-placement="top"
                                                   data-content="This appointment is less than 24 hours away or requires additional information to modify. Please call 1 (800) 824-3647 to change this appointment.">
                                                    Reschedule
                                                </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {process.env.REACT_APP_USE_SERVICE_SCHEDULING === 'true' ? (
                    <div className="card py-2">
                            <span className="p-3 scheduler-text-heading" style={{color: "#0067b1"}}>
                            Need to Schedule a New Appointment?
                            </span>
                        {serviceState.contactAddress === "" ?
                            <div>
                                <span className="p-3 scheduler-text-heading" style={{color: "black"}}>
                                    Please call us at <a href="tel:8005783647">(800) 824-3647</a> if you need to schedule an appointment.
                                </span>
                            </div>
                        :
                            <>
                                {appointments.length < 2 ?
                                    <div className="col-12 col-lg-4">
                                        <button type="button" className="p-2 scheduler-button scheduler-text-body"
                                                onClick={() => handleNewAppointment()}>Schedule Appointment
                                        </button>
                                    </div>
                                    :
                                    <div>
                                        <span className="p-3 scheduler-text-heading" style={{color: "black"}}>
                                    Please call us at <a href="tel:8008243647">(800) 824-3647</a> if you need to schedule another appointment.
                                    </span>
                                    </div>
                                }
                            </>
                        }
                    </div>
                ) : (
                    <div className="card py-2">
                        <span className="p-3 scheduler-text-heading" style={{color: "#0067b1"}}>
                        Need to Schedule a New Appointment?
                        </span>
                        <div>
                            <span className="p-3 scheduler-text-heading" style={{color: "black"}}>
                                Please call us at <a href="tel:8005783647">(800) 824-3647</a> if you need to schedule an appointment.
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}