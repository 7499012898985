import React, {useEffect, useState} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {schedulingActions} from "../../redux-modules/SchedulingModule";
import AppointmentCalendar from "../../components/AppointmentCalendar";
import "../../components/appointment-calendar.css";

export default function RescheduleAppointmentPage(props) {
    const scheduling = useSelector(state => state.scheduling);
    const params = useParams();
    const [exactAppointment, setExactAppointment] = useState(false);
    
    useEffect(() => {
        let datesWithSlots = scheduling.selectedMonth.days.filter(x => x.hasOwnProperty("appointmentSlots") && x.appointmentSlots !== null);
        if (datesWithSlots.length > 0)
            setExactAppointment(datesWithSlots[0].appointmentSlots[0].exactAppointment);
    }, [scheduling.selectedMonth])
    
    if (scheduling.appointmentScheduled) {
        return <Navigate to="/appointments"/>;
    } else {
        return (
            <div className="mx-3">
                <div className="row">
                    <div className="col">
                        <Link to="/appointments" className="scheduler-link" style={{fontSize: "1.25em"}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <span className="ml-1 mb-2">Back To My Appointments</span>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3 className="appointments-header">
                            Reschedule Appointment
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="appointments-instructions">
                            Select the {exactAppointment ? "start time" : "window of arrival"} that best fits your needs.
                        </p>
                    </div>
                </div>
                {!exactAppointment && 
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="appointments-instructions">
                                We will send you a confirmation email the day prior to your appointment with an estimated time of arrival.
                            </p>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 text-center">
                        {params.appointmentId && (
                            <AppointmentCalendar
                                selectedMonth={scheduling.selectedMonth}
                                selectedDay={scheduling.selectedDay}
                                onDaySelected={schedulingActions.daySelected}
                                onPreviousMonthSelected={schedulingActions.selectPreviousMonth}
                                onNextMonthSelected={schedulingActions.selectNextMonth}
                                getSlots={() => schedulingActions.getRescheduleSlots(params.appointmentId)}
                                onSlotClick={schedulingActions.onSlotClick}
                                isSlotsLoaded={scheduling.appointmentsLoaded}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}