const defaultLandingPageName = "default";

function useLandingPage() {
    const LOCAL_QUERY_PARAM = "local";

    async function getLandingPage(landingPageName) {

        if (!landingPageName) landingPageName = defaultLandingPageName;
        const landingPageFilePath = `data/landing-pages/${landingPageName}.json`;
        return await fetch(landingPageFilePath)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                return data;
            })
            .catch((err) => {
                return getLandingPage(defaultLandingPageName);
            });

        //     let contactFormContent = await ContentApi.getContactFormContent(landingPageName);
        //     console.log('apiData', contactFormContent);
        //     return contactFormContent;

    }

    const defaultPageContent = {
        "header": {
            "imageSrc": "images/contact-form/quotes-lander.webp",
            "imageAltText": "3+ Million Safer Happier Pets, 315 Dog Years of Experience, 1 Year Money-Back Guarantee",
            "primaryBannerText": "",
            "secondaryBannerText": ""
        },
        "leftContent": {
            "contentType": "image",
            "contentSrc": "images/contact-form/bullet-image.webp",
            "imageAltText": "bullet-image",
            "headerText": "Included in the Cost of Invisible Fence<sup>&reg;</sup> Brand System",
            "bodyText": "At Invisible Fence<sup>&reg;</sup> Brand, it is our mission to keep dogs and cats safely contained at home. With products like our new Boundary Plus Smart<sup>&trade;</sup> System, we can provide reassurance in your pet's safety unlike any other dog fence.",
            "pawListItems": []
        },
        "bottomContent": {
            "headerText": "Get an Invisible Fence<sup>&reg;</sup> Brand Quote Today!",
            "subText": "There are several variables that determine price of Invisible Fence<sup>&reg;</sup> Brand, which is why we ask customers to schedule a free in-home consultation with one of our Pet Consultants, so we can customize a solution and a quote for your pet.",
            "accordionItems": [
                {
                    "headerText": "Cost of Invisible Fence<sup>&reg;</sup> Brand Compared to Traditional Fence",
                    "imageURL": "images/contact-form/accordion/Traditional_Icon_Blue.webp",
                    "bodyText": "Materials such as wood, vinyl, and chain link are expensive, especially when you start calculating the cost to fence in larger properties. On average, the price of an Invisible Fence<sup>&reg;</sup> Brand system is 45% - 80% more cost-effective than a traditional fence when fencing in a quarter acre property."
                },
                {
                    "headerText": "Pricing for Customized Solutions",
                    "imageURL": "images/contact-form/accordion/Training_Icon_Blue.webp",
                    "bodyText": "People ask 'What does Invisible Fence<sup>&reg;</sup> Brand cost?' But no two Invisible Fence<sup>&reg;</sup> Brand quotes are the same. Our Pet Consultants will find a custom solution that is personalized for your home and pet."
                },
                {
                    "headerText": "Exclusive Boundary Plus<sup>&reg;</sup> Technology",
                    "imageURL": "images/contact-form/accordion/BPlus_Icon_Blue.webp",
                    "bodyText": "Available only through Invisible Fence<sup>&reg;</sup> Brand, Boundary Plus is the only electric fence that provides up to 30% more yard space and prevents pets from being locked out of their yard."
                },
                {
                    "headerText": "Includes More Than a DIY Dog Fence",
                    "imageURL": "images/contact-form/accordion/Pricing_Icon.webp",
                    "bodyText": "Unlike DIY dog fences and other wireless containment systems, the cost of Invisible Fence<sup>&reg;</sup> Brand systems include professional installation and certified trainers teaching your pet their new boundaries at their own pace. This way, your dog is confident in their yard and you are confident in their safety!"
                },
                {
                    "headerText": "Money-Back Guarantee",
                    "imageURL": "images/contact-form/accordion/MoneyBack_Icon_Blue.webp",
                    "bodyText": "Invisible Fence<sup>&reg;</sup> Brand includes a Money-Back Performance Guarantee as well as a minimum one-year equipment warranty – so not only is your pet secure, so is your investment."
                }
            ]
        },
        "formHeader": "Take the Next Step to Pet Safety",
        "footerText": ""
    };

    return {
        getLandingPage,
        LOCAL_QUERY_PARAM,
        defaultPageContent
    };
}

export default useLandingPage;