import React from "react";

export default function Footer() {
    return (
        <div>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 mt-4 border-top"
                    style={{backgroundColor: '#00375f'}}>
                <div className="col-md-12 text-center d-flex">
                    <span className="mb-3 mb-md-0 text-white">&copy; 2023 Invisible Fence<sup>&reg;</sup> Brand. All rights reserved.</span>
                </div>
            </footer>
        </div>
    )
}