import React from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import Accordion from "../../accordion/Accordion"
import AccordionItem from "../../accordion/AccordionItem";
import parse from 'html-react-parser';

export default function BottomContent({headerText, subText, accordionItems}) {
    return (
        <div>
            <div className="row">
                <div className="col-12 text-center">
                    <h2>{parse(headerText)}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center mx-3">
                    <p>{parse(subText)}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {accordionItems.length > 0 && <Accordion>
                        {accordionItems.map((accordionItem, idx) =>
                            <AccordionItem key={idx} title={parse(accordionItem.headerText)}
                                           iconUrl={accordionItem.imageURL}
                                           bodyText={parse(accordionItem.bodyText)}
                            />
                        )}
                    </Accordion>
                    }
                </div>
            </div>
        </div>
    )
}