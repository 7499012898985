import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js"
import {modalActions} from "../../redux-modules/ModalModule";

export default function InfoModal(props) {
    const info = useSelector(state => state.modals.info);
    const dispatch = useDispatch();

    useEffect(() => {
        info.visible ? $("#info-modal").modal("show") : $("#info-modal").modal("hide");
    }, [info])

    const acknowledge = () => {
        dispatch(modalActions.hideInfo());
    }

    return (
        <div id="info-modal" className="modal hide mt-" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" aria-label="Close" onClick={acknowledge}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" dangerouslySetInnerHTML={{__html: info.text}}>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={acknowledge}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}