import React, {Children, useState} from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import "./accordion.css";
import _uniqueId from 'lodash/uniqueId';
import AccordionRow from "./AccordionRow";

export default function Accordion({children}) {
    const arrayChildren = Children.toArray(children);
    const [accordionId] = useState(_uniqueId('accordion-'));
    const [expandedCollapseId, setExpandedCollapseId] = useState("");

    return (
        <div className="accordion" id={accordionId}>
            {Children.map(arrayChildren, (child, index) => {
                    return (
                        <AccordionRow accordionId={accordionId} title={child.props.title} iconUrl={child.props.iconUrl}
                                      bodyText={child.props.bodyText}
                                      onCollapseExpanded={(cId) => setExpandedCollapseId(cId)}
                                      expandedCollapseId={expandedCollapseId}/>
                    )

                }
            )}
        </div>
    )
}

