import React from "react";
import spinner from "../../images/spinner.gif";
import "./default-loading-screen.css";

export default function DefaultLoadingScreen() {
    return (
        <div id="loading-container" className="appointment-loading-screen-container">
            <div id="exampleModalCenter" className="modal fade show modal-container" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h2>Please keep this browser open while we process your request.</h2>
                                        <img id="img-spinner" src={spinner} alt="Loading..."/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}