import React, {Component} from "react";
import {Form} from "reactstrap";
import {v4 as uuidv4} from "uuid";
import {autobind} from "../../utility/utilities";
import $ from "jquery";

export class ValidatedForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: props.id ?? uuidv4(),
            formName: props.formName ?? uuidv4()
        };
        autobind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        let valid = $("#" + this.state.uuid + " .standard-validation-error").length <= 0;
        if (valid && typeof (this.props.onSubmit) === "function") {
            this.props.onSubmit(event);
        }
    }

    render() {
        return (
            <Form id={this.state.uuid} name={this.state.formName} onSubmit={this.handleSubmit}>
                {this.props.children}
            </Form>
        )
    }
}