import {createSlice} from "@reduxjs/toolkit";
import {AuthenticationApi} from "../api/AuthenticationApi";
import {ServiceApi} from "../api/ServiceApi";
import {serviceActions} from "./ServiceModule";

const SCHEDULER_SESSION_COOKIE = "_ifb_scheduler_session";

const userState = {
    authenticated: false,
    challenge: null,
    challengeSent: false,
    error: null,
    givenName: null,
    showContactForm: false,
    showContactFormSubmitted: false
};

const PAGE_TITLES = {
    Login: 'IFB Scheduler - Login',
    AuthChallenge: 'IFB Scheduler - Authentication'
};

let userReducers = {
    authenticated: (state, action) => {
        state.authenticated = true;
        state.challengeSent = false;
        state.challenge = null;
        state.error = null;
        state.showContactForm = false;
        state.showContactFormSubmitted = false;
    },
    authChallengeSent: (state, action) => {
        state.challenge = action.payload;
        state.challengeSent = true;
        state.error = null;
        document.title = PAGE_TITLES.AuthChallenge;
        state.showContactForm = false;
        state.showContactFormSubmitted = false;
    },
    authError: (state, action) => {
        state.error = action.payload.error;
        state.showContactForm = action.payload.showContactForm;
    },
    givenNameRetrieved: (state, action) => {
        state.authenticated = true;
        state.givenName = action.payload;
        state.error = null;
    },
    logout: (state, action) => {
        document.cookie = SCHEDULER_SESSION_COOKIE + "=;";
        state.authenticated = false;
    },
    resetAuth: (state, action) => {
        state.authenticated = false;
        state.challenge = null;
        state.challengeSent = false;
        document.title = PAGE_TITLES.Login;
        state.showContactForm = false;
        state.error = null;
        state.showContactFormSubmitted = false;
    },
    setLoginPage: (state, action) => {
        document.title = PAGE_TITLES.Login;
    },
    unauthenticated: (state, action) => {
        state.authenticated = false;
        state.challengeSent = false;
        state.givenName = null;
        state.error = null;
        state.showContactForm = false;
        state.showContactFormSubmitted = false;
    },
    hubspotFormSubmitted: (state, action) => {
        state.authenticated = false;
        state.challengeSent = false;
        state.givenName = null;
        state.error = null;
        state.showContactForm = false;
        state.showContactFormSubmitted = true;
    }
};

const userModule = createSlice({
    name: "user",
    initialState: userState,
    reducers: userReducers
});

let asyncUserActions = {
    authChallenge: (email) => {
        return async (dispatch, getState) => {
            try {
                let challenge = await AuthenticationApi.challenge(email);
                dispatch(userModule.actions.authChallengeSent(challenge));
            } catch (ex) {
                console.error(ex);
                dispatch(userModule.actions.authError({error: "We’re sorry, we couldn’t find an account associated with that email address. To contact us or request service, please fill out the form below.", showContactForm: true}));
            }
        }
    },
    authResponse: (email, accessCode) => {
        return async (dispatch, getState) => {
            try {
                let session = await AuthenticationApi.respond(email, accessCode, getState().user.challenge)
                document.cookie = SCHEDULER_SESSION_COOKIE + "=" + session;
                dispatch(userModule.actions.authenticated());
                let address = await ServiceApi.getCustomerAddress();
                dispatch(serviceActions.setContactAddress(address));                
            } catch (ex) {
                console.error(ex);
                dispatch(userModule.actions.authError({error: "Invalid access code", showContactForm: false }));
            }
        }
    },
    getGivenName: () => {
        return async (dispatch, getState) => {
            try {
                let givenName = await AuthenticationApi.getGivenName();
                dispatch(userModule.actions.givenNameRetrieved(givenName));
            } catch (ex) {
                dispatch(userModule.actions.unauthenticated());
            }
        }
    }
}

export const userActions = {...userModule.actions, ...asyncUserActions};
export default userModule.reducer;