import React from "react";
import parse from "html-react-parser";

function HeaderBanner({primaryText, secondaryText}) {

    if (primaryText === "") return;

    return (
        <div className="row text-center" style={{background: "#0067b1"}}>
            <div className="col-12 my-4">
                <h3 style={{color: "#ffffff"}}>
                    {parse(primaryText)}
                </h3>

                {secondaryText.length > 0 &&
                    (
                        <span style={{fontSize: "18px", color: "#ffffff"}}>{parse(secondaryText)}</span>
                    )
                }
            </div>
        </div>
    )
}

export default function Header({imageSrc, imageAltText, primaryBannerText, secondaryBannerText}) {
    return (
        <div className="contact-page-header-container mb-4">
            <img src={imageSrc}
                 className="contact-page-header-img d-none d-md-block"
                 alt={imageAltText}/>
            <HeaderBanner primaryText={primaryBannerText} secondaryText={secondaryBannerText}/>
        </div>
    )
}