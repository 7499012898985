import React from "react";
import {useDispatch} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js";

export default function VerifyAddressPage({streetAddress, onVerifyAddressNext}) {
    const dispatch = useDispatch();

    const onNext = () => {
        dispatch(onVerifyAddressNext());
    }

    return (
        <div className="container">
            <div className="card py-2">
                <div className="row">
                    <div className="col-12">
                        <p className="pl-3 scheduler-text-heading" style={{color: "#0067b1"}}>
                            Scheduling Appointment For:
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="pl-3">
                            <b className="scheduler-text-body">{streetAddress}</b>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="pl-3">
                            If you need to schedule an appointment at a different address, please call us at <a
                            href="tel:8008243647" className="no-break">(800) 824-3647</a>.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row float-right">
                <div className="col-12">
                    <button className="p-2 m-2 scheduler-button scheduler-text-body" onClick={() => onNext()}>Next
                    </button>
                </div>
            </div>
        </div>
    )
}