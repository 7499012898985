import $ from "jquery";

export class ServiceApi {

    static getCustomerAddress() {
        return $.ajax("/api/service/address", {
            type: "GET"
        });
    }
    
    static getCustomerDetails() {
        return $.ajax("/api/service/customer", {
            type: "GET"
        });
    }

    static createOtherIssueCase(payload) {
        return $.ajax("/api/service/createOtherIssueCase", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(payload)
        });
    }
    
    static getAppointments(payload) {
        return $.ajax("/api/service/appointments", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(payload)
        });
    }

    static scheduleAppointment(payload) {
        return $.ajax("/api/service/schedule", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(payload)
        })
    }
}