import $ from "jquery";

export class EFenceApi {
    static getAppointments(request) {
        return $.ajax("api/dealer/efx/appointments", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(request)
        });
    }

    static scheduleAppointment(slot) {
        return $.ajax("/api/dealer/efx/schedule", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(slot)
        });
    }
}