import React, {useEffect, useState} from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import "./contact-page.css";
import {useParams, useSearchParams} from "react-router-dom";
import Header from "../../components/prospect-scheduling/contact-page/Header";
import LeftContent from "../../components/prospect-scheduling/contact-page/LeftContent";
import ContactForm from "../../components/prospect-scheduling/ContactForm";
import BottomContent from "../../components/prospect-scheduling/contact-page/BottomContent";
import Footer from "../../components/prospect-scheduling/Footer";
import parse from "html-react-parser";
import useLandingPage from "../../hooks/useLandingPage"
import useSchedulerLogging from "../../hooks/useSchedulerLogging";

export default function ContactPage({onFormSubmit, promoCode}) {
    const [pageContent, setPageContent] = useState(null);
    const {landingPageName} = useParams();
    const [searchParams] = useSearchParams();
    const {getLandingPage} = useLandingPage();
    const {setContactFormViewed, setExistingCustomer} = useSchedulerLogging();

    useEffect(() => {
        async function fetchContent() {
            let contactFormContent = await getLandingPage(landingPageName);
            setPageContent(contactFormContent);
        }

        fetchContent();

        setContactFormViewed(window.location.href, window.location.origin, window.location.pathname, window.location.search);
    }, []);

    return (
        <div>
            {pageContent &&
                <Header imageSrc={pageContent.header.imageSrc}
                        imageAltText={pageContent.header.imageAltText}
                        primaryBannerText={pageContent.header.primaryBannerText}
                        secondaryBannerText={pageContent.header.secondaryBannerText}/>

            }
            <div className="container contact-body-container">
                <div className="row justify-content-center contact-page-container" tabIndex="0">
                    <div className="col-12 col-md-6 order-2 order-md-1 contact-page-copy-container">
                        {pageContent && <LeftContent
                            contentType={pageContent.leftContent.contentType}
                            contentSrc={pageContent.leftContent.contentSrc}
                            imageAltText={pageContent.leftContent.imageAltText}
                            headerText={pageContent.leftContent.headerText}
                            bodyText={pageContent.leftContent.bodyText}
                            pawListItems={pageContent.leftContent.pawListItems}
                        />
                        }
                    </div>
                    <div className="col-12 col-md-6 order-1 order-md-2">
                        {pageContent && <h3><strong>{parse(pageContent.formHeader)}</strong></h3>}
                        <ContactForm onFormSubmit={onFormSubmit} promoCode={promoCode}/>
                        <div className="row mt-4">
                            <div className="col-12">
                                <p className="existing-customer-link">Existing Customer needing service? <a
                                    onClick={setExistingCustomer}
                                    href="https://new.invisiblefence.com/customer-resources">Request a service
                                    visit.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                {pageContent && <BottomContent headerText={pageContent.bottomContent.headerText}
                                               subText={pageContent.bottomContent.subText}
                                               accordionItems={pageContent.bottomContent.accordionItems}
                />
                }
                {pageContent && pageContent.footerText.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <span className="footer-text">{parse(pageContent.footerText)}</span>
                        </div>
                    </div>
                )}
            </div>
            <Footer/>
        </div>
    )
}