import React from "react";
import Header from "./Header";

export default function App(props) {
    return (
        <div>
            <Header/>
            <div className="container-fluid">
                {props.children}
            </div>
        </div>
    );
}
