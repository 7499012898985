import React from "react";
import {Navbar} from "reactstrap";
import "./header.css"
import logo from "../images/ifb-logo.png";

export default function Header() {
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <a href="https://www.invisiblefence.com" className="mr-auto navbar-brand">
                    <img src={logo} alt="Invisible Fence© Brand"/>
                </a>
                <a href="tel:18005783647" className="ml-auto scheduler-text-body" style={{color: "white"}}>Call Us! 1
                    (800) 578-3647</a>
            </Navbar>
        </header>
    );
}
