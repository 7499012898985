import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js"
import {modalActions} from "../../redux-modules/ModalModule";
import parse from "html-react-parser";

export default function ErrorModal(props) {
    const error = useSelector(state => state.modals.error);
    const dispatch = useDispatch();

    useEffect(() => {
        error.visible ? $("#error-modal").modal("show") : $("#error-modal").modal("hide");
    }, [error])

    const acknowledge = () => {
        dispatch(modalActions.hideError());
    }

    return (
        <div id="error-modal" className="modal hide mt-" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-danger">
                        <button type="button" className="close" aria-label="Close" onClick={acknowledge}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {error.text &&
                            <p>{parse(error.text)}</p>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={acknowledge}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}