import $ from "jquery";

export class AuthenticationApi {
    static challenge(email){
        return $.ajax("/api/authentication/challenge?email=" + encodeURIComponent(email), {
            type: "GET"
        });
    }
    static respond(email, accessCode, challenge){
        return $.ajax("/api/authentication/respond?email=" + encodeURIComponent(email) + "&accessCode=" + accessCode + "&challenge=" + encodeURIComponent(challenge), {
            type: "GET"
        })
    }
    static getGivenName(){
        return $.ajax("/api/authentication/givenname", {
            type: "GET"
        });
    }
}