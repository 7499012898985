import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";

export default function SystemDownPage({onFormSubmit}) {
    const dispatch = useDispatch();
    const service = useSelector(state => state.service);

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm();

    const onSubmit = (data) => {
        dispatch(onFormSubmit({
            hasMajorLandscaping: parseInt(data.hasMajorLandscaping) === 1,
            systemDownLength: parseInt(data.systemDownLength)
        }));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12"><span className="scheduler-text-heading" style={{color: "#0067b1"}}>
                    Tell Us More About Your Issue
                </span></div>
            </div>
            <div className="row pl-3">
                <div className="col-12">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>Have you had any major landscaping done?<sup
                                className='text-red'>*</sup>:</label>
                            {errors.hasMajorLandscaping?.type === "required" && (
                                <p role="alert" className="text-red">Please select an option.</p>
                            )}
                            <div className="form-check pb-3">
                                <input {...register("hasMajorLandscaping", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="1"
                                       id="landscaping-yes"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="landscaping-yes" className="form-check-label pl-3">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check pb-3">
                                <input {...register("hasMajorLandscaping", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="0"
                                       id="landscaping-no"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="landscaping-no" className="form-check-label pl-3">
                                    No
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>How long has your system been down?<sup
                                className='text-red'>*</sup>:</label>
                            {errors.systemDownLength?.type === "required" && (
                                <p role="alert" className="text-red">Please select an option.</p>
                            )}
                            <div className="form-check pb-3">
                                <input {...register("systemDownLength", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="1"
                                       id="down-over"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="down-over" className="form-check-label pl-3">
                                    More than one year
                                </label>
                            </div>
                            <div className="form-check pb-3">
                                <input {...register("systemDownLength", {required: true})}
                                       className="form-check-input form-check-input"
                                       type="radio"
                                       value="2"
                                       id="down-under"
                                       style={{width: '25px', height: '25px'}}
                                />
                                <label htmlFor="down-under" className="form-check-label pl-3">
                                    Less than one year
                                </label>
                            </div>
                        </div>
                        <input type="submit" value="Next" className="contact-form-submit-btn float-left"/>
                    </form>
                </div>
            </div>
        </div>
    )
}