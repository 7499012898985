import React, {lazy, useEffect, useState} from "react";
import {Navigate, Route, Routes, useNavigate, useSearchParams} from "react-router-dom";
import ContactPage from "../../pages/prospect-scheduling/ContactPage";
import {useDispatch, useSelector} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js"
import {prospectActions, ProspectViews} from "../../redux-modules/ProspectModule";
import "./prospect-scheduler.css";
import useSchedulerLogging from "../../hooks/useSchedulerLogging";
import RedirectHandler from "./RedirectHandler";
import WebAssistHandler from "./WebAssistHandler";

const ExistingCustomerPage = lazy(() => import("../../pages/prospect-scheduling/cod/ExistingCustomerPage"));
const ConsultTypePage = lazy(() => import( "../../pages/prospect-scheduling/cod/ConsultTypePage"));
const AppointmentsPage = lazy(() => import( "../../pages/prospect-scheduling/cod/AppointmentsPage"));
const ConfirmationPage = lazy(() => import( "../../pages/prospect-scheduling/cod/ConfirmationPage"));
const GetAppointmentsErrorPage = lazy(() => import( "../../pages/prospect-scheduling/errors/GetAppointmentsErrorPage"));
const SchedulingErrorViewPage = lazy(() => import( "../../pages/prospect-scheduling/errors/SchedulingErrorViewPage"));
const DealerSelection = lazy(() => import( "../../pages/prospect-scheduling/DealerSelection"));
const EFence = lazy(() => import( "../../pages/prospect-scheduling/efence/EFence"));
const CRM = lazy(() => import( "../../pages/prospect-scheduling/crm/CRM"));
export default function ProspectScheduler(props) {
    const prospect = useSelector(state => state.prospect);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [promoCode, setPromoCode] = useState("");
    const {setPromoCookie} = useSchedulerLogging();

    useEffect(() => {
        for (const [key, value] of searchParams.entries()) {
            if (key.toLowerCase() === "promo") {
                console.log(key, value)
                setPromoCode(value);
                setPromoCookie(value);
                return;
            }
        }
    }, []);

    useEffect(() => {
        document.title = `${ProspectViews.BasePath.Title}${prospect.currentView.Title}`;
        if (prospect.currentView !== ProspectViews.ContactForm) {
            navigate(`/${ProspectViews.BasePath.Path}/${prospect.currentView.Path}`);
        }

    }, [prospect.currentView, dispatch])

    return (
        <Routes>
            <Route path={`/test`} element={
                <ConfirmationPage
                    contactId={"0038D00000HM3u8QAD"}
                    apptId={"12345"}
                    consultType={"TEST CONSULT"}
                    startTimeLabel={"START TIME LABEL"}
                    appointmentDate={"2023-11-15T21:00:00Z"}
                    slotDisplay={"APPT TIME"}
                    onReferralEmailSubmitted={prospectActions.COD.saveReferralEmail}
                />
            }/>
            <Route exact path='/' element={<Navigate replace to={`${ProspectViews.ContactForm.Path}`}/>}/>
            <Route exact path={`/${ProspectViews.RedirectHandler.Path}`} element={
                <RedirectHandler/>
            }/>
            <Route exact path={`/${ProspectViews.WebAssistHandler.Path}`} element={
               <WebAssistHandler />
            }/>
            <Route exact path={`/${ProspectViews.ContactForm.Path}`} element={
                <ContactPage onFormSubmit={prospectActions.onContactFormSubmit} promoCode={promoCode}/>
            }/>
            <Route exact path={`/${ProspectViews.ContactForm.Path}/:landingPageName`} element={
                <ContactPage onFormSubmit={prospectActions.onContactFormSubmit} promoCode={promoCode}/>
            }/>
            <Route exact path={`/${ProspectViews.DealerSelection.Path}`} element={
                <DealerSelection dealers={prospect.dealers} onDealerSelection={prospectActions.onDealerSelection}/>
            }/>
            <Route path={`/${ProspectViews.NoDealers}`} element={<SchedulingErrorViewPage>
                    <span>A representative will be in touch within 1-2 business days to schedule your free Invisible
                        Fence<sup>&reg;</sup> Brand consultation.</span>
            </SchedulingErrorViewPage>}/>
            <Route path={`/${ProspectViews.Errors.Recaptcha.Path}`}
                   element={<SchedulingErrorViewPage headerText="We're sorry, but an error has occurred.">
                       <span>Please give us a call at <a href="tel:8005783647">(800) 578-3647</a> to schedule your free consultation.</span>
                   </SchedulingErrorViewPage>}/>
            <Route path={`/${ProspectViews.NoDealers.Path}`} element={<GetAppointmentsErrorPage/>}/>
            <Route path={`/${ProspectViews.COD.Errors.ContactForm.Path}`} element={<GetAppointmentsErrorPage/>}/>
            <Route path={`/${ProspectViews.COD.ExistingCustomer.Path}`} element={
                <ExistingCustomerPage
                    contactId={prospect.contact.contactId}
                    onMovedOrMajorLayout={prospectActions.COD.onMovedOrMajorLayout}
                    onOtherIssueSelected={prospectActions.COD.onExistingCustomerOtherIssueSelected}
                />
            }/>
            <Route path={`/${ProspectViews.COD.ExistingCustomer_RemoteConsultCreated.Path}`}
                   element={
                       <SchedulingErrorViewPage headerText="We have received your submission.">
                           <span>A representative will be in touch to schedule your appointment.</span>
                       </SchedulingErrorViewPage>}
            />
            <Route path={`/${ProspectViews.COD.ExistingCustomer_OtherIssue.Path}`} 
                   element={
                <SchedulingErrorViewPage headerText="We have received your submission.">
                    <span>A representative will be in touch to schedule your appointment.</span>
                </SchedulingErrorViewPage>}
            />
            <Route path={`/${ProspectViews.COD.Appointments.Path}`} element={<AppointmentsPage
                onDaySelected={prospectActions.daySelected}
                onPreviousMonthSelected={prospectActions.selectPreviousMonth}
                onNextMonthSelected={prospectActions.selectNextMonth}
                onSlotClick={prospectActions.COD.onSlotClick}
                getSlots={prospectActions.COD.getSlots}
                isSlotsLoaded={prospect.appointmentsLoaded}
            />}
            />
            <Route path={`/${ProspectViews.COD.Confirmation.Path}`} element={
                (prospect.scheduledSlot.slot != null &&
                    <ConfirmationPage
                        contactId={prospect.scheduledSlot.slot.contactId}
                        apptId={prospect.scheduledSlot.slot.id}
                        consultType={prospect.scheduledSlot.slot.workType_CustomerLabel}                        
                        isExactAppointment={prospect.scheduledSlot.slot.exactAppointment}
                        appointmentDate={prospect.scheduledSlot.slot.arrivalWindowStartLocal}
                        slotDisplay={prospect.scheduledSlot.slot.slotDisplay}
                        onReferralEmailSubmitted={prospectActions.COD.saveReferralEmail}
                        estimatedAppointmentLength={prospect.scheduledSlot.slot.estimatedAppointmentLength}
                    />
                )
            }/>
            <Route path={`/${ProspectViews.COD.Errors.Appointments.Path}`} element={
                <SchedulingErrorViewPage headerText="We apologize!">
                    <span>An error occurred on our side, but we have your information and a representative will contact you in 1-2 business days to schedule your free, no obligation consultation. We apologize again for the inconvenience.</span>
                </SchedulingErrorViewPage>
            }/>
            <Route path={`/${ProspectViews.COD.Errors.NoWorkTypes.Path}`} element={
                <SchedulingErrorViewPage>
                    <span>A representative will be in touch within 1-2 business days to schedule your free Invisible
                        Fence<sup>&reg;</sup> Brand consultation.</span>
                </SchedulingErrorViewPage>
            }/>
            <Route path={`/${ProspectViews.COD.Errors.Schedule.Path}`} element={
                <SchedulingErrorViewPage headerText="We apologize!">
                    <span>An error occurred on our side, but we have your information and a representative will contact you in 1-2 business days to schedule your free, no obligation consultation. We apologize again for the inconvenience.</span>
                </SchedulingErrorViewPage>
            }/>

            {/* CRM */}
            <Route path={`/${ProspectViews.CRM.BasePath.Path}/*`} element={
                <CRM></CRM>
            }/>

            {/* EFence */}
            <Route path={`/${ProspectViews.EFence.BasePath.Path}/*`} element={
                <EFence></EFence>
            }/>

            {/*Email*/}
            <Route path={`/${ProspectViews.Email.Confirmation.Path}/*`} element={
                <SchedulingErrorViewPage headerText="Thank you for your submission.">
                    <span>Your local dealer has received your information and will be in touch within the next few business days.</span>
                </SchedulingErrorViewPage>
            }/>

            <Route path={`/${ProspectViews.Email.Error.Path}/*`} element={
                <SchedulingErrorViewPage headerText="Thank you for your submission.">
                    <span>Your local dealer has received your information and will be in touch within the next few business days.</span>
                </SchedulingErrorViewPage>
            }/>

        </Routes>
    )
}