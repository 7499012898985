import {createSlice} from "@reduxjs/toolkit";
import {SchedulingApi} from "../api/SchedulingApi";
import buildCalendar from "../calendar/calendar";
import {modalActions} from "./ModalModule";
import {userActions} from "./UserModule";
import {loadingScreenActions} from "./LoadingScreenModule";
import AppointmentLoadingScreen from "../components/prospect-scheduling/AppointmentLoadingScreen";
import {formatDate} from "../utility/utilities";

let initialCalendar = buildCalendar();

const schedulingState = {
    appointments: [],
    appointmentScheduled: false,
    reloadAppointments: true,
    appointmentsLoaded: false,
    calendar: initialCalendar,
    selectedMonth: initialCalendar.months[0],
    selectedDay: null
};

const PAGE_TITLES = {
    Dashboard: 'IFB Scheduler - Appointment Dashboard',
    Calendar: 'IFB Scheduler - Calendar'
};

let schedulingReducers = {
    setAppointmentDashboardPage: (state, action) => {
        document.title = PAGE_TITLES.Dashboard;
    },
    setReloadAppointments: (state, action) => {
        state.reloadAppointments = action.payload;
    },
    appointmentCanceled: (state, action) => {
        state.appointments = [];
        state.reloadAppointments = true;
    },
    appointmentsLoaded: (state, action) => {
        state.appointments = action.payload;
        state.appointmentScheduled = false;
        state.calendar = buildCalendar();
        state.selectedMonth = state.calendar.months[0];
        state.selectedDay = null;
        state.reloadAppointments = false;
    },
    appointmentScheduled: (state, action) => {
        state.reloadAppointments = true;
        state.appointmentScheduled = true;
    },
    appointmentSlotsLoaded: (state, action) => {
        state.calendar = buildCalendar(action.payload);
        state.selectedMonth = state.calendar.months[0];
        state.appointmentScheduled = false;
        state.appointmentsLoaded = true;
        document.title = PAGE_TITLES.Calendar;
    },
    daySelected: (state, action) => {
        state.selectedDay = action.payload;
    },
    selectNextMonth: (state, action) => {
        let index = state.selectedMonth.index + 1;
        if (index < state.calendar.months.length) {
            state.selectedMonth = state.calendar.months[index];
        }
    },
    selectPreviousMonth: (state, action) => {
        let index = state.selectedMonth.index - 1;
        if (index >= 0) {
            state.selectedMonth = state.calendar.months[index];
        }
    }
};

const schedulingModule = createSlice({
    name: "scheduling",
    initialState: schedulingState,
    reducers: schedulingReducers
});

let asyncSchedulingActions = {
    cancelAppointment: (appointmentId) => {
        return async (dispatch, getState) => {
            try {
                await SchedulingApi.cancelAppointment(appointmentId);
                dispatch(modalActions.showInfo("Appointment Canceled"));
                dispatch(schedulingModule.actions.appointmentCanceled());
            } catch (ex) {
                if (ex.status === 401) {
                    dispatch(userActions.unauthenticated());
                } else {
                    console.error(ex);
                    dispatch(modalActions.showError("There was an error canceling your appointment"));

                }
            }
        }
    },
    getAppointments: () => {
        return async (dispatch, getState) => {
            try {
                let appointments = await SchedulingApi.getAppointments();
                dispatch(schedulingModule.actions.appointmentsLoaded(appointments));
            } catch (ex) {
                if (ex.status === 401) {
                    dispatch(userActions.unauthenticated());
                } else {
                    console.error(ex);
                    dispatch(modalActions.showError("There was an error getting your appointment"));
                }
            }
        }
    },
    getRescheduleSlots: (appointmentId) => {
        return async (dispatch, getState) => {
            try {
                dispatch(loadingScreenActions.setCustomLoadingScreen(AppointmentLoadingScreen));
                let slots = await SchedulingApi.getRescheduleSlots(appointmentId);
                dispatch(loadingScreenActions.setDefaultLoadingScreen());
                dispatch(schedulingModule.actions.appointmentSlotsLoaded(slots));
            } catch (ex) {
                if (ex.status === 401) {
                    dispatch(userActions.unauthenticated());
                } else {
                    console.error(ex);
                    dispatch(modalActions.showError("There was an error finding open appointments"));
                }
            }
        }
    },
    onSlotClick: (slot) => {
        return async (dispatch, getState) => {

            let payload = {
                text: `Are you sure you want to reschedule this appointment?`,
                action: () => {
                    dispatch(schedulingActions.scheduleAppointment(slot));
                }
            }
            dispatch(modalActions.showConfirm(payload));
        }
    },
    scheduleAppointment: (slot) => {
        return async (dispatch, getState) => {
            try {
                await SchedulingApi.scheduleAppointment(slot);
                dispatch(modalActions.showInfo(`Your appointment has been rescheduled for ${formatDate(slot.start)} ${slot.exactAppointment ? "starting at " : "with arrival window from"} ${slot.slotDisplay}.<br/><br/>We will send you a confirmation email the day prior to your appointment with an estimated arrival time.`));
                dispatch(schedulingModule.actions.appointmentScheduled());
            } catch (ex) {
                if (ex.status === 401) {
                    dispatch(userActions.unauthenticated());
                } else {
                    console.error(ex);
                    dispatch(modalActions.showError("There was an error rescheduling your appointment.  Please call us at <a href=\"tel:8008243647\">(800)&nbsp;824-3647</a> to finish rescheduling your appointment."));
                    dispatch(schedulingModule.actions.appointmentScheduled());
                }
            }
        }
    },
    reloadAppointments: () => {
        return async (dispatch, getState) => {
            dispatch(schedulingModule.actions.setReloadAppointments(true));
        }
    }
}

export const schedulingActions = {...schedulingModule.actions, ...asyncSchedulingActions}
export default schedulingModule.reducer;