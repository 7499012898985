import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalActions} from "../../redux-modules/ModalModule";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js"
import parse from "html-react-parser";

export default function ConfirmModal(props) {
    const confirmState = useSelector(state => state.modals.confirm);
    const dispatch = useDispatch();

    useEffect(() => {
        confirmState.visible ? $("#confirm-modal").modal("show") : $("#confirm-modal").modal("hide");
    }, [confirmState])

    const confirm = () => {
        dispatch(modalActions.hideConfirm());
        if (typeof (confirmState.action) === "function") {
            confirmState.action();
        }
    }

    const dismissModal = () => {
        dispatch(modalActions.hideConfirm());
    }

    return (
        <div id="confirm-modal" className="modal hide" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" aria-label="Close" onClick={dismissModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {confirmState.text &&
                            <p>{parse(confirmState.text)}</p>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={confirm}>Yes</button>
                        <button type="button" className="btn btn-primary" onClick={dismissModal}>No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}