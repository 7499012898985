import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from "../../utility/utilities";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import "./stripe.css";
import {useForm} from "react-hook-form";
import {modalActions} from "../../redux-modules/ModalModule";

export default function ReviewPage({
                                       workTypeName,
                                       selectedAppointmentDate,
                                       selectedAppointmentTime,
                                       onFormSubmit,
                                       onBack
                                   }) {
    const dispatch = useDispatch();
    const service = useSelector(state => state.service);
    const [isService, setIsService] = useState(workTypeName.toLowerCase().indexOf("service") >= 0);
    const [showAccessQuestions, setShowAccessQuestions] = useState(false);
    const [cardError, setCardError] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setValue,
        getValues
    } = useForm();

    const watchIsHomeForAccess = watch("isHomeForAccess", "1");
    const watchHasUnattendedAccess = watch("hasUnattendedAccess", "");

    useEffect(() => {
        if (service.defaultPhone) {
            setValue('contactNumber', service.defaultPhone)
        }

        if (isService && service.workType.id !== '08q1M000000fxTtQAI')
            setShowAccessQuestions(true);

    }, [])

    const onSubmit = (data) => {
        let payload = null;
        let access = false;
        if (showAccessQuestions) {
            access = data.isHomeForAccess === "1" ? false : data.hasUnattendedAccess === "1";
        }

        let accessNotes = access ? data.accessNotes : "";

        if (service.defaultPaymentMethod) {
            payload = {
                stripePayload: null,
                contactNumber: data.contactNumber,
                hasUnattendedAccess: access,
                accessNotes: accessNotes
            };
            dispatch(onFormSubmit(payload));
        } else {
            const card = elements.getElement(CardElement);
            if (card === null) {
                return;
            }

            let stripePayload = '';
            stripe.createToken(card).then(function (result) {

                if (result.error !== undefined) {
                    setCardError(result.error.message);
                    return;
                }

                stripePayload = JSON.stringify(result.token);

                payload = {
                    stripePayload: stripePayload,
                    contactNumber: data.contactNumber,
                    hasUnattendedAccess: access,
                    accessNotes: accessNotes
                };
                dispatch(onFormSubmit(payload));
            });
        }
    }

    const onCardChange = (event) => {
        let isValid = true;
        let errorStr = '';
        if (event.error) {
            errorStr = event.error.message;
            isValid = false;
        } else {
            errorStr = '';
        }

        setCardError(errorStr);
    }

    const checkUnattendedAccessRequired = (value) => {
        if (showAccessQuestions && getValues('isHomeForAccess') === "0") {
            return !!value;
        }
        return true;
    }

    const checkAccessNotesRequired = (value) => {
        if (showAccessQuestions && getValues('isHomeForAccess') === "0" && getValues('hasUnattendedAccess') === "1") {
            return !!value;
        }
        return true;
    }

    const showCardChangeModal = () => {
        dispatch(modalActions.showInfo("Let our representative know during your appointment or give us a call after scheduling your appointment at <a href=\"tel:8008243647\">(800) 824-3647.</a>"));
    }

    const onBackPress = () => {
        dispatch(onBack());
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>You're almost done!</h2>
                </div>
            </div>
            {workTypeName && selectedAppointmentDate && selectedAppointmentTime &&
                <>
                    <div className="row">
                        <div className="col-12">
                            <span>Please confirm your details to schedule your Invisible
                                Fence<sup>&reg;</sup> Brand {workTypeName} appointment:</span>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-12">
                            <b>Date: </b> <span className='no-break'>{formatDate(selectedAppointmentDate)}</span>
                        </div>
                        <div className="col-12">
                            <b>Estimated Arrival Window: </b> <span
                            className='no-break'>{selectedAppointmentTime}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h5 className="pt-2">We will send you a confirmation email the day prior to your appointment
                                with an estimated arrival time for your {isService ? "technician" : "trainer"}.</h5>
                        </div>
                    </div>
                </>
            }
            <div className="row">
                <div className="col-12">

                    <div className="form-group pt-3">

                        {service.defaultPaymentMethod &&
                            <div>
                                <h3>Credit Card on File</h3>
                                <div className="col-12">
                                    <b>{service.defaultPaymentMethod.brand} x{service.defaultPaymentMethod.last4} Exp. {service.defaultPaymentMethod.expMonth}/{service.defaultPaymentMethod.expYear}</b>
                                </div>
                                <div className="col-12">
                                    <span className="click-link" onClick={showCardChangeModal}>Need to change your card on file?</span>
                                </div>
                            </div>

                        }

                        {service.defaultPaymentMethod == null &&
                            <>
                                <h3>Preferred Credit Card</h3>
                                <div id="stripeForm">
                                    <CardElement
                                        onChange={onCardChange}
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#424770',
                                                    '::placeholder': {
                                                        color: '#aab7c4',
                                                    },
                                                },
                                                invalid: {
                                                    color: '#9e2146',
                                                },
                                            },
                                        }}
                                    />
                                    <h4 id="card-errors" className="text-red">{cardError}</h4>
                                </div>
                            </>
                        }
                        <div className='py-3'>
                            <span>
                                Your card will not be charged until time of service.  Time and cost for each appointment varies depending on the service needed. Your technician will cover cost during your appointment.
                                {isService && <> <br/><br/> Save money by purchasing a 1 year service plan from your
                                    technician! </>}
                            </span>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-group pb-3">
                            <h3 className="">Best Contact <span className="no-break">Number<sup
                                className="text-red">*</sup>:</span>
                            </h3>
                            {errors.contactNumber?.type === "required" && (
                                <p role="alert" className="text-red">Please enter a valid 10-digit phone number</p>
                            )}
                            <input type="tel" {...register("contactNumber", {
                                required: true,
                                pattern: /\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
                            })}
                                   className="contact-form-input form-control"
                            />
                        </div>

                        {showAccessQuestions &&
                            <>
                                <div className="form-group">
                                    <h3>Will you be home to give our team access to your <span className="no-break">transmitter?<sup
                                        className='text-red'>*</sup>:</span></h3>
                                    {errors.isHomeForAccess?.type === "required" && (
                                        <p role="alert" className="text-red">Please select an option.</p>
                                    )}
                                    <div className="form-check pb-3">
                                        <input {...register("isHomeForAccess", {required: showAccessQuestions})}
                                               className="form-check-input form-check-input"
                                               type="radio"
                                               value="1"
                                               id="isHome-yes"
                                               style={{width: '25px', height: '25px'}}
                                        />
                                        <label htmlFor="isHome-yes" className="form-check-label pl-3">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check pb-3">
                                        <input {...register("isHomeForAccess", {required: showAccessQuestions})}
                                               className="form-check-input form-check-input"
                                               type="radio"
                                               value="0"
                                               id="isHome-no"
                                               style={{width: '25px', height: '25px'}}
                                        />
                                        <label htmlFor="isHome-no" className="form-check-label pl-3">
                                            No
                                        </label>
                                    </div>
                                </div>

                                {(watchIsHomeForAccess === "0") &&
                                    <div className="form-group">
                                        <h3>Do you authorize our technician access to your transmitter if you are
                                            not <span className="no-break">home?<sup
                                                className='text-red'>*</sup>:</span></h3>
                                        {errors.hasUnattendedAccess?.type === "required" && (
                                            <p role="alert" className="text-red">Please select an option.</p>
                                        )}
                                        <div className="form-check pb-3">
                                            <input {...register("hasUnattendedAccess", {
                                                    validate: {
                                                        required: checkUnattendedAccessRequired
                                                    }
                                                }
                                            )}
                                                   className="form-check-input form-check-input"
                                                   type="radio"
                                                   value="1"
                                                   id="access-yes"
                                                   style={{width: '25px', height: '25px'}}
                                            />
                                            <label htmlFor="access-yes" className="form-check-label pl-3">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check pb-3">
                                            <input {...register("hasUnattendedAccess", {
                                                validate: {
                                                    required: checkUnattendedAccessRequired
                                                }
                                            })}
                                                   className="form-check-input form-check-input"
                                                   type="radio"
                                                   value="0"
                                                   id="access-no"
                                                   style={{width: '25px', height: '25px'}}
                                            />
                                            <label htmlFor="access-no" className="form-check-label pl-3">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                }

                                {(watchIsHomeForAccess === "0" && watchHasUnattendedAccess === "1") &&
                                    <div className="form-group">
                                        <h3>Please provide any necessary details on how we can access your
                                            transmitter<sup
                                                className="text-red">*</sup>:</h3>
                                        {errors.accessNotes?.type === "required" && (
                                            <p role="alert" className="text-red">Field is required</p>
                                        )}
                                        <small>Please make sure your pet’s receiver collar is accessible for our technician - include its location in your notes below.</small>
                                        <textarea {...register("accessNotes", {
                                            validate: {
                                                required: checkAccessNotesRequired
                                            },
                                            maxLength: 1000
                                        })}
                                                  rows="5"
                                                  className="contact-form-input form-control mt-3"
                                                  placeholder="Ex. garage code, key location, receiver collar location"
                                        />
                                    </div>
                                }

                                {(watchIsHomeForAccess === "0" && watchHasUnattendedAccess === "0") &&
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h4 className="text-red">You must be home for this appointment or leave
                                                access for our technician. If you cannot do either, please <span
                                                    className="click-link" onClick={onBackPress}>click here</span> to
                                                choose a day and time where one of these options works for you.</h4>
                                        </div>
                                    </div>
                                }

                            </>

                        }

                        <div className="row py-4">
                            <div className="col-12">
                                <h5>If you need to cancel or update your appointment, please do so at least 24 hours
                                    before your scheduled appointment.
                                    If you cancel under 24 hours, you may be subject to a $50 missed appointment
                                    fee.</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <span className="float-left btn btn-secondary btn-lg" onClick={onBackPress}>Back
                                </span>
                                <input type="submit" value="Schedule Appointment"
                                       className={"float-right btn btn-lg btn-" + ((showAccessQuestions && watchIsHomeForAccess === "0" && watchHasUnattendedAccess === "0") ? "secondary" : "primary")}
                                       disabled={(showAccessQuestions && watchIsHomeForAccess === "0" && watchHasUnattendedAccess === "0")}/>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}