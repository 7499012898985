import $ from "jquery";

let asyncActions = [];

const loadingSpinnerMiddleware = (store) => (next) => (action) =>{
    if(typeof($) !== "undefined"){
        if(typeof(action) === "function"){
            asyncActions.push(action);
            $("#loadingSpinner").removeClass("d-none");
        }else{
            asyncActions.pop();
            if(asyncActions.length === 0){
                $("#loadingSpinner").addClass("d-none");
            }
        }
    }else{
        console.warn("jQuery must be loaded to see loading spinner");
    }
    return next(action);
};

export default loadingSpinnerMiddleware;