import React, {Component} from "react";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {autobind} from "../../utility/utilities";
import parse from 'html-react-parser';

const validate = require("validate.js");
const multiValueListTypes = ['radio', 'checkbox'];
validate.validators.presence.options = {message: "Field is required"};

export class ValidatedInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayValidation: false,
            invalid: false,
            validationErrors: []
        }
        autobind(this);
    }


    componentDidMount() {
        this.validateInput(this.props.value, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.validateInput(this.props.value, false);
        }
    }

    handleBlur(event) {
        this.validateInput(this.props.value, true);
        if (typeof (this.props.onBlur) === "function") {
            this.props.onBlur(event);
        }
    }

    handleFocus(event) {
        this.validateInput(this.props.value, false);
        if (typeof (this.props.onFocus) === "function") {
            this.props.onFocus(event);
        }
    }

    handleChange(event) {
        if (typeof (this.props.onChange) === "function") {
            this.props.onChange(event);
        }
    }

    validateInput(value, displayValidation) {
        if (typeof (this.props.validation) === "undefined") {
            return;
        }
        let errors = validate.single(value, this.props.validation);
        this.setState({
            displayValidation: displayValidation,
            invalid: displayValidation && typeof (errors) !== "undefined",
            validationErrors: typeof (errors) === "undefined" ? [] : errors
        });
    }

    onRadioBtnClick(rSelected) {
        if (typeof (this.props.onChange) === "function") {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: rSelected
                }
            });
        }
    }

    render() {
        return (
            <FormGroup>
                {this.props.label && <Label>{parse(this.props.label)}</Label>}
                {this.props.subLabel &&
                    <>
                        <br/>
                        <Label className={this.props.subLabelClassName}>{parse(this.props.subLabel)}</Label>
                    </>
                }

                {(multiValueListTypes.includes(this.props.type)) &&
                    <>
                        {this.props.inputs.map((s, i) => {
                            let inputId = this.props.name + i.toString();
                            return (
                                <div className="form-check pb-3" key={inputId}>
                                    <Input id={inputId} className="form-check-input"
                                           onClick={() => this.onRadioBtnClick(s.value)}
                                           style={{width: '25px', height: '25px'}}
                                           type="radio" name={this.props.name} value={s.value}
                                           onFocus={this.handleFocus} onBlur={this.handleBlur}
                                           onChange={this.handleChange} disabled={this.props.disabled}/>
                                    <label className="form-check-label pl-3" htmlFor={inputId}>
                                        {parse(s.label)}
                                    </label>
                                </div>
                            )
                        })}
                    </>
                }

                {(!multiValueListTypes.includes(this.props.type)) &&
                    <Input id={this.props.id} name={this.props.name} type={this.props.type}
                           className={this.props.className}
                           placeholder={this.props.placeholder}
                           value={this.props.value || ""} onFocus={this.handleFocus} onBlur={this.handleBlur}
                           onChange={this.handleChange}
                           disabled={this.props.disabled}
                           invalid={this.state.invalid || this.props.customError !== null}
                           rows={this.props.rows || ""} cols={this.props.cols || ""}/>

                }
                {
                    this.props.customError ? <div className="invalid-feedback">{this.props.customError}</div> : null
                }
                {
                    this.state.displayValidation ?
                        this.state.validationErrors.map(error => {
                            return <FormFeedback key={error}
                                                 className="standard-validation-error">{error}</FormFeedback>
                        }) : null
                }
            </FormGroup>
        )
    }
}