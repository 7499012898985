import {configureStore} from "@reduxjs/toolkit";
import userReducer from "../redux-modules/UserModule";
import schedulerReducer from "../redux-modules/SchedulingModule";
import modalReducer from "../redux-modules/ModalModule";
import prospectReducer from "../redux-modules/ProspectModule";
import serviceReducer from "../redux-modules/ServiceModule";
import loadingScreenReducer from "../redux-modules/LoadingScreenModule";
import thunk from "redux-thunk";
import loadingSpinnerMiddleware from "../redux-middleware/LoadingSpinnerMiddleware";

function store() {
    return configureStore({
        reducer: {
            modals: modalReducer,
            scheduling: schedulerReducer,
            user: userReducer,
            prospect: prospectReducer,
            loadingScreen: loadingScreenReducer,
            service: serviceReducer
        },
        middleware: [loadingSpinnerMiddleware, thunk]
    })
}

export default store;