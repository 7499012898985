import React from "react";
import spinner from "../../images/spinner.gif";
import "./appointment-loading-screen.css";

export default function AppointmentLoadingScreen() {
    return (
        <div id="loading-container" className="appointment-loading-screen-container">
            <div id="exampleModalCenter" className="modal fade show modal-container" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h2>Please wait while we load available appointments in your area.</h2>
                                        <h5>This may take 5-15 seconds. Once complete, you will see available dates
                                            and times to choose from.</h5>
                                        <img id="img-spinner" src={spinner} alt="Loading..."/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}