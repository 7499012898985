import React from "react";
import {useDispatch} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js";
import "./system-type-page.css";

export default function TrainingPetTypePage({onPetTypeSelection}) {
    const dispatch = useDispatch();

    const onPetSelected = (petId) => {
        dispatch(onPetTypeSelection(parseInt(petId)));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <span className="scheduler-text-heading" style={{color: "#0067b1"}}>
                        Which pet is escaping your system?
                    </span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6 py-4">
                    <button onClick={() => onPetSelected(1)} className="btn btn-primary btn-lg w-100">Dog</button>
                </div>
                <div className="col-12 col-md-6 py-4">
                    <button onClick={() => onPetSelected(2)} className="btn btn-primary btn-lg w-100">Cat</button>
                </div>
            </div>
        </div>
    )
}