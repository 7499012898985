import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {prospectActions, ProspectViews} from "../../redux-modules/ProspectModule";
import useSchedulerLogging from "../../hooks/useSchedulerLogging";
import {ProspectApi} from "../../api/ProspectApi";

export default function WebAssistHandler() {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {setContactFormViewed} = useSchedulerLogging();

    useEffect(() => {

        const processRedirect = async () => {
            try {
                let encodedId = searchParams.get("uid");

                let formData = await ProspectApi.getWebAssist(encodedId);

                let promo = searchParams.get("promo");

                if (!(formData.firstName && formData.lastName && formData.address && formData.zip && formData.phoneNumber && formData.email) || formData.zip.length < 5) {
                    navigate(`/${ProspectViews.BasePath.Path}/${ProspectViews.ContactForm.Path}`);
                    dispatch(prospectActions.showContactForm());
                    return;
                }

                let utmSource = searchParams.get("source");
                let utmCampaign = searchParams.get("campaign");
                let utmMedium = searchParams.get("medium");
                let path = "web-assist";

                var href = `${window.location.origin}/${path}`;
                var origin = window.location.origin;
                var windowPath = `/prospect/contact/${path}`;

                var urlParams = "";
                urlParams += `?promo=${(promo === "undefined" ? "" : promo)}&UTM_Campaign=${(utmCampaign === "undefined" ? "" : utmCampaign)}&UTM_Source=${(utmSource === "undefined" ? "" : utmSource)}&UTM_Medium=${(utmMedium === "undefined" ? "" : utmMedium)}`;

                await setContactFormViewed(href, origin, windowPath, urlParams);

                dispatch(prospectActions.onContactFormSubmit(formData, process.env.REACT_APP_RECAPTCHA_BYPASS_KEY));
            }catch(ex) {
                navigate(`/${ProspectViews.BasePath.Path}/${ProspectViews.ContactForm.Path}`);
                dispatch(prospectActions.showContactForm());
            }
        }

        processRedirect();

    }, [])

    return (<></>)

}