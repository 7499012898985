import React from "react";
import {useSelector} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js"
import AppointmentCalendar from "../../components/AppointmentCalendar";

export default function AppointmentsPage({
                                             onDaySelected,
                                             onPreviousMonthSelected,
                                             onNextMonthSelected,
                                             getSlots,
                                             onSlotClick,
                                             isSlotsLoaded
                                         }) {
    const serviceState = useSelector(state => state.service);

    return (
        <div className="mx-2">
            {serviceState.workType && (
                <div className="row">
                    <div className="col">
                        <h3 className="appointments-header">
                            Schedule {serviceState.workType.customerLabel} Appointment
                        </h3>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-12 text-center">
                    <div>
                        <h3 className="text-red">Keep your pet on a leash until your system is fixed.</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <p>
                        Select any day highlighted in blue to see and select available arrival windows.
                    </p>
                </div>
                <div className="col-12 text-center">
                    <p>
                        We will send you a confirmation email the day prior to your appointment with an estimated
                        arrival time for your technician.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <AppointmentCalendar
                        selectedMonth={serviceState.selectedMonth}
                        selectedDay={serviceState.selectedDay}
                        onDaySelected={onDaySelected}
                        onPreviousMonthSelected={onPreviousMonthSelected}
                        onNextMonthSelected={onNextMonthSelected}
                        getSlots={getSlots}
                        onSlotClick={onSlotClick}
                        isSlotsLoaded={isSlotsLoaded}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <hr style={{maxWidth: '70%'}}/>
                </div>
            </div>
        </div>
    )

}