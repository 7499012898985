import $ from "jquery";

export class SchedulingApi {
    static cancelAppointment(appointmentId){
        return $.ajax("/api/scheduling/appointments/" + appointmentId + "/cancel", {
            type: "PUT"
        })
    }
    static getAppointments(){
        return $.ajax("/api/scheduling/appointments", {
            type: "GET"
        });
    }
    static getRescheduleSlots(appointmentId){
        return $.ajax("/api/scheduling/appointments/" + appointmentId + "/rescheduleslots", {
            type: "GET"
        })
    }
    static scheduleAppointment(slot){
        return $.ajax("api/scheduling/appointments/schedule", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(slot)
        })
    }
}