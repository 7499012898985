import $ from "jquery";

export class DealerApi {
    static sendDealerEmails(request) {
        return $.ajax("api/dealer/email", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(request)
        });
    }
}