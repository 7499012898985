import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {ServiceViews} from "../../redux-modules/ServiceModule";

export default function SystemIssuePage({onFormSubmit, systemName}) {
    const dispatch = useDispatch();
    const service = useSelector(state => state.service);


    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setValue
    } = useForm();

    useEffect(() => {
        if (service.currentView === ServiceViews.SystemIssue) {
            setValue('comments', service.serviceRequest.comments);
        }
    }, [service.currentView]);

    const onSubmit = (data) => {
        dispatch(onFormSubmit({
            comments: data.comments
        }));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12"><span className="scheduler-text-heading" style={{color: "#0067b1"}}>
                    What's Happening?
                </span></div>
            </div>
            <div className="row pl-3">
                <div className="col-12">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className="">Please explain the issue(s) you're having with your {systemName} system<sup
                                className="text-red">*</sup>:</label>
                            <br/>                            
                            {errors.comments?.type === "required" && (
                                <p role="alert" className="text-red">Please describe your issue.</p>
                            )}
                            <textarea {...register("comments", {required: true})}
                                      rows="5"
                                      className="contact-form-input form-control"
                            />
                        </div>


                        <input type="submit" value="Next" className="contact-form-submit-btn float-left"/>
                    </form>
                </div>
            </div>

            <div className="row pt-3">
                <div className="col-12">
                    <h5>Don't see what you need? Give us a call at <a href="tel:8008243647">(800)
                        824-3647.</a></h5>
                </div>
            </div>
        </div>
    )
}