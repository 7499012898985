import React from "react";
import spinner from "../images/spinner.gif";
import "./loading-spinner.css";
import {useSelector} from "react-redux";

function DefaultSpinner() {
    return (
        <div id="spinner" className="spinner">
            <img src={spinner} alt="Loading..." id="img-spinner"/>
        </div>
    )
}

export {DefaultSpinner}

export default function LoadingSpinner({customSpinner}) {
    const {LoadingScreen} = useSelector(state => state.loadingScreen);

    return (
        <div id="loadingSpinner" className="spinner-container d-none">
            {<LoadingScreen/>}
        </div>
    )
}