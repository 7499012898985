import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesLeft, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {formatDate} from "../utility/utilities";
import "./appointment-calendar.css";
import $ from "jquery";

export default function AppointmentCalendar({
                                                selectedMonth,
                                                selectedDay,
                                                onDaySelected,
                                                onPreviousMonthSelected,
                                                onNextMonthSelected,
                                                getSlots,
                                                onSlotClick,
                                                isSlotsLoaded
                                            }) {
    const dispatch = useDispatch();
    const [monthManuallyChanged, setMonthManuallyChanged] = useState(false);

    const handleDayClick = (day) => {
        if (day.appointmentSlots) {
            document.getElementById("appointment-slots").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            
            clearSelectedDate();
            
            $('#' + day.id).addClass("selected");
            dispatch(onDaySelected(day));
        }
    };

    const handlePreviousClick = () => {
        setMonthManuallyChanged(true);
        dispatch(onPreviousMonthSelected());
    }

    const handleNextClick = () => {
        setMonthManuallyChanged(true);
        dispatch(onNextMonthSelected());
    }

    const handleSlotClick = (slot) => {
        dispatch(onSlotClick(slot));
    }

    const clearSelectedDate = () => {
        $.map($('.scheduler-calendar-body').children('.selected'), function(calDate, idx) {
            $(calDate).removeClass('selected');
        });
    }
    
    useEffect(() => {
        dispatch(getSlots());
    }, [dispatch])

    useEffect(() => {
        if (selectedDay != null)
            dispatch(onDaySelected(null));

        clearSelectedDate();

        if (isSlotsLoaded && selectedMonth.days !== null && selectedMonth.days.length > 0) {
            let datesWithSlots = selectedMonth.days.filter(x => x.hasOwnProperty("appointmentSlots") && x.appointmentSlots !== null);
            if (datesWithSlots.length === 0) {
                if (!monthManuallyChanged) {
                    handleNextClick();
                }
            } else {
                handleDayClick(datesWithSlots[0]);  
            }             
        }
        setMonthManuallyChanged(false);

    }, [isSlotsLoaded, selectedMonth])

    return (
        <div>
            <div className="row">
                <div id="calendar" className="scheduler-calendar col-lg-6 col-sm-12">
                    <header className="scheduler-calendar-header">
                        <section className="scheduler-calendar-controls">
                            <div className="scheduler-control-left">
                                <FontAwesomeIcon icon={faAnglesLeft} onClick={handlePreviousClick}/>
                            </div>
                            <div className="scheduler-control-label text-center">
                                <span>{selectedMonth.label}</span>
                            </div>
                            <div className="scheduler-control-right">
                                <FontAwesomeIcon icon={faAnglesRight} onClick={handleNextClick}/>
                            </div>
                        </section>
                        <section className="scheduler-calendar-weeks">
                            <div>S</div>
                            <div>M</div>
                            <div>T</div>
                            <div>W</div>
                            <div>T</div>
                            <div>F</div>
                            <div>S</div>
                        </section>
                    </header>
                    <section className="scheduler-calendar-body">
                        {
                            selectedMonth.days.map((d, i) => {
                                return (
                                    <div key={d.date} id={d.id} className={d.class} onClick={() => handleDayClick(d)}>
                                        {d.day}
                                    </div>
                                )
                            })
                        }
                    </section>
                </div>
                <div id="appointment-slots" className="col-lg-6 col-sm-12">
                    {
                        selectedDay == null ? null :
                            <div className="scheduler-slots-container">
                                <p className="scheduler-help-text-body">Choose an available time
                                    on {formatDate(selectedDay.date)} </p>
                                {
                                    selectedDay.appointmentSlots.map((s, i) => {
                                        return (
                                            <div key={s.start} className="scheduler-slot-button-container">
                                                <button className="scheduler-slot-button"
                                                        onClick={() => handleSlotClick(s)}>
                                                    {s.slotDisplay}
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}