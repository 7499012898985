import React from "react";
import {useDispatch} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js";
import "./system-type-page.css";
import SystemType from "../../components/service-scheduling/SystemType";

export default function SystemTypePage({onSystemTypeSelection}) {
    const dispatch = useDispatch();

    const onSystemSelection = (typeId, name) => {
        dispatch(onSystemTypeSelection({systemTypeId: typeId, system: name}));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <span className="scheduler-text-heading" style={{color: "#0067b1"}}>
                        Select Your System
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p>Which system needs service?</p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <SystemType
                        systemTypeId={1}
                        systemTypeName="Classic"
                        displayName="Classic System"
                        description="Buried wire, blue transmitter"
                        imageUrl="images/service-scheduling/system-type/700-800-Transmitter-Option.jpg"
                        onSystemSelection={onSystemSelection}
                    />                    
                </div>
                <div className="col-12 col-md-6">
                    <SystemType
                        systemTypeId={5}
                        systemTypeName="BoundaryPlus"
                        displayName="Boundary Plus System"
                        description="Buried wire, blue and silver transmitter"
                        imageUrl="images/service-scheduling/system-type/BoundaryPlus-Transmitter-Option.jpg"
                        onSystemSelection={onSystemSelection}
                    />
                </div>
                
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <SystemType
                        systemTypeId={2}
                        systemTypeName="Smart"
                        displayName="Smart System"
                        description="Mobile app connected, black transmitter"
                        imageUrl="images/service-scheduling/system-type/Smart-Transmitter-Option.jpg"
                        onSystemSelection={onSystemSelection}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <SystemType
                        systemTypeId={3}
                        systemTypeName="GPS"
                        displayName="GPS Wireless"
                        description="No buried wire, black transmitter"
                        imageUrl="images/service-scheduling/system-type/GPS-Transmitter-Option.jpg"
                        onSystemSelection={onSystemSelection}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <SystemType
                        systemTypeId={6}
                        systemTypeName="LTE"
                        displayName="LTE"
                        description="LTE System"
                        imageUrl="images/service-scheduling/system-type/LTE-Option.png"
                        onSystemSelection={onSystemSelection}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <SystemType
                        systemTypeId={4}
                        systemTypeName="Doorman"
                        displayName="Doorman"
                        description="Electronic Pet Door"
                        imageUrl="images/service-scheduling/system-type/Doorman-Pet-Door-Option.jpg"
                        onSystemSelection={onSystemSelection}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <p>Don't see what you need? Give us a call at <a className="no-break"
                        href="tel:8008243647">(800) 824-3647</a></p>
                </div>
            </div>

        </div>
    )
}