import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js";
import {ValidatedForm, ValidatedInput} from "../forms";
import {ReCaptcha} from "../../utility/recaptcha";
import {getDateTimeStringTest} from "../../utility/utilities";

export default function ContactForm({onFormSubmit, promoCode}) {
    const recaptcha_api_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const prospect = useSelector(state => state.prospect);

    const [state, setState] = useState({
        firstName: null,
        lastName: null,
        address: null,
        city: "",
        state: "",
        zip: null,
        phoneNumber: null,
        email: null,
        promo: promoCode
    });

    useEffect(() => {
        if ((process.env.REACT_APP_TEST_CONTACT_FORM === "true")) {
            let prefix = process.env.REACT_APP_TEST_CONTACT_FORM_PREFIX;
            setState({
                firstName: `${prefix}${getDateTimeStringTest()}`,
                lastName: `${prefix}${getDateTimeStringTest()}`,
                address: `${getDateTimeStringTest()} ${process.env.REACT_APP_TEST_CONTACT_FORM_STREET}`,
                city: "",
                state: "",
                zip: process.env.REACT_APP_TEST_CONTACT_FORM_ZIP,
                phoneNumber: getDateTimeStringTest(),
                email: `${prefix}${getDateTimeStringTest()}@gmail.com`,
                promo: promoCode
            })
        }
    }, []);

    useEffect(() => {
        setState(prevState => ({...prevState, promo: promoCode}));
    }, [promoCode])

    useEffect(() => {
        checkFormValid();
    }, [state])

    const [submitDisabled, setSubmitDisabled] = useState(true);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        setState(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }

    function checkFormValid() {
        if (state.firstName && state.firstName.length > 0
            && state.lastName && state.lastName.length > 0
            && state.address && state.address.length > 0
            && state.zip && state.zip.length >= 5
            && state.phoneNumber && state.phoneNumber.length > 0
            && state.email && state.email.length > 0) {
            setSubmitDisabled(false);
            return;
        }
        setSubmitDisabled(true);
    }

    const handleFormSubmit = () => {
        ReCaptcha.executeRecaptcha("contact_form_submission").then(function (token) {
            dispatch(onFormSubmit(state, token));
        });
    }

    return (
        <div>
            <div className="row" tabIndex="0">
                <div className="col-12">
                    <ValidatedForm id="ProspectContactForm" formName="prospect-contact-form"
                                   onSubmit={handleFormSubmit}>
                        <ValidatedInput id="firstName" placeholder="First Name*" name="firstName" type="text"
                                        className="contact-form-input"
                                        value={state.firstName}
                                        onChange={handleChange}
                                        validation={{
                                            presence: {allowEmpty: false},
                                            length: {maximum: 50}
                                        }}
                                        customError={prospect.error}/>
                        <ValidatedInput id="lastName" placeholder="Last Name*" name="lastName" type="text"
                                        className="contact-form-input"
                                        value={state.lastName}
                                        onChange={handleChange}
                                        validation={{
                                            presence: {allowEmpty: false},
                                            length: {maximum: 50}
                                        }}
                                        customError={prospect.error}/>
                        <ValidatedInput id="address" placeholder="Street Address*" name="address" type="text"
                                        className="contact-form-input"
                                        value={state.address}
                                        onChange={handleChange}
                                        validation={{
                                            presence: {allowEmpty: false},
                                            length: {maximum: 255}
                                        }}
                                        customError={prospect.error}/>
                        <legend className="form-legend">Please enter 5-6 character postal code only.</legend>
                        <ValidatedInput id="zip"
                                        placeholder="Postal Code*" name="zip" type="text"
                                        className="contact-form-input"
                                        value={state.zip}
                                        onChange={handleChange}
                                        validation={{
                                            presence: {allowEmpty: false},
                                            format: {
                                                //pattern: /\d{5}/,
                                                pattern: /^((\d{5}-\d{4})|(\d{5})|([a-zA-Z]\d[a-zA-Z]\s?\d[a-zA-Z]\d))$/,
                                                message: "Must be a 5-6 character zip code"
                                            }
                                        }}
                                        customError={prospect.error}/>
                        <ValidatedInput id="phoneNumber"
                                        placeholder="Phone Number*"
                                        name="phoneNumber"
                                        type="phone"
                                        className="contact-form-input"
                                        value={state.phoneNumber}
                                        onChange={handleChange}
                                        validation={{
                                            presence: {allowEmpty: false},
                                            format: {
                                                pattern: /\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                                message: "Please enter a valid 10-digit phone number"
                                            }
                                        }}
                                        customError={prospect.error}/>
                        <ValidatedInput id="email" placeholder="Email*" name="email" type="email" value={state.email}
                                        className="contact-form-input"
                                        onChange={handleChange}
                                        validation={{
                                            presence: {
                                                allowEmpty: false
                                            },
                                            email: true
                                        }}
                                        customError={prospect.error}/>
                        <button className="contact-form-submit-btn float-left" type="submit"
                                disabled={submitDisabled}>Continue
                        </button>
                    </ValidatedForm>
                </div>
            </div>
        </div>
    )

}