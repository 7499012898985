import $ from "jquery";

export class ProspectApi {

    static getDealersForContact(contactForm) {
        return $.ajax(`/api/dealer`, {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(contactForm)
        });
    }

    static getWorkTypes(contactId, zip) {
        return $.ajax(`/api/prospect/worktypes?contactId=${encodeURIComponent(contactId)}&zipCode=${encodeURIComponent(zip)}`, {
            type: "get"
        });
    }

    static captureLead(customer, recaptchaToken) {
        return $.ajax("api/prospect/lead", {
            type: "POST",
            dataType: "json",
            headers: {
                "recaptcha-token": recaptchaToken
            },
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(customer)
        });
    }

    static upsertCustomer(customer) {
        return $.ajax("api/prospect/customer", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(customer)
        });
    }

    static getAppointments(customerId, workTypeId) {
        return $.ajax(`/api/prospect/appointments?customerId=${encodeURIComponent(customerId)}&workTypeId=${encodeURIComponent(workTypeId)}`,
            {
                type: "get"
            });
    }

    static getConsultAppointments(customerId) {
        return $.ajax(`/api/prospect/consult-appointments?customerId=${encodeURIComponent(customerId)}&loadAllAppointments=${encodeURIComponent(true)}`,
            {
                type: "get"
            });
    }
    
    static scheduleAppointment(slot) {
        return $.ajax("/api/prospect/appointments/schedule", {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(slot)
        });
    }

    static saveReferralEmail(contactId, email) {
        return $.ajax(`/api/prospect/referral?contactId=${contactId}&email=${email}`, {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify("")
        });
    }
    
    static createRemoteConsult(contactId) {
        return $.ajax(`/api/prospect/remote-consult?contactId=${contactId}`, {
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify("")
        });
    }

    static getWebAssist(encryptedContactId) {
        return $.ajax(`/api/prospect/web-assist?encId=${encodeURIComponent(encryptedContactId)}`, {
            type: "get"
        });
    }
}