import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import Layout from "./components/Layout";
import LoadingSpinner from "./components/LoadingSpinner";
import ConfirmModal from "./components/modals/ConfirmModal";
import InfoModal from "./components/modals/InfoModal";
import ErrorModal from "./components/modals/ErrorModal";
import ProspectScheduler from "./components/prospect-scheduling/ProspectScheduler";
import RescheduleAppointmentPage from "./pages/current-customer/RescheduleAppointmentPage";
import AppointmentDashboardPage from "./pages/current-customer/AppointmentDashboardPage";
import "./styles/custom.css";
import ServiceScheduler from "./components/service-scheduling/ServiceScheduler";
import {ServiceViews} from "./redux-modules/ServiceModule";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {ProspectViews} from "./redux-modules/ProspectModule";
const AuthenticationPage = lazy(() => import( "./pages/authentication/AuthenticationPage"));
const stripeKey = window.IsProduction() ? process.env.REACT_APP_STRIPE_KEY_PROD : process.env.REACT_APP_STRIPE_KEY_TEST;
const stripePromise = loadStripe(stripeKey);

export default function App(props) {
    const user = useSelector(state => state.user);

    const stripeOptions = {
        appearance: {
            theme: 'stripe'
        }
    };

    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <Layout>
                <LoadingSpinner/>
                <ConfirmModal/>
                <ErrorModal/>
                <InfoModal/>
                <Routes>
                    <Route exact path='/'
                           element={user.authenticated ? <Navigate replace to="/appointments"/> :
                               <AuthenticationPage/>}/>
                    <Route path="/appointments"
                           element={!user.authenticated ? <Navigate replace to="/"/> : <AppointmentDashboardPage/>}/>
                    <Route path="/appointments/:appointmentId/reschedule"
                           element={!user.authenticated ? <Navigate replace to="/"/> : <RescheduleAppointmentPage/>}/>
                    <Route path={`/${ProspectViews.BasePath.Path}/*`} element={<ProspectScheduler/>}/>
                    <Route path={`/${ServiceViews.BasePath.Path}/*`} element={<ServiceScheduler/>}/>
                </Routes>
            </Layout>
        </Elements>
    );
}
